import React, { Component } from 'react';

import CustomThemeProvider from 'layouts/CustomThemeProvider';

const AppLayout = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <CustomThemeProvider>
          <WrappedComponent {...this.props} />
        </CustomThemeProvider>
      );
    }
  };
};
export default AppLayout;
