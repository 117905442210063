import axios from 'axios';
import qs from 'qs';
import { camelize, decamelize } from './keysConverter';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const toFormData = object => {
  const formData = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export default {
  get(url, params = {}, withCamelize = true) {
    return axios
      .get(url, {
        params,
        paramsSerializer: parameters => qs.stringify(parameters, { arrayFormat: 'brackets' }),
      })
      .then(response => (withCamelize ? camelize(response) : response));
  },

  post(url, json) {
    const body = decamelize(json);
    return axios.post(url, body).then(camelize);
  },

  postFormData(url, headers, params) {
    const formData = toFormData(params);
    return axios
      .post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(camelize);
  },

  patch(url, json) {
    const body = decamelize(json);
    return axios.patch(url, body).then(camelize);
  },

  delete(url, json) {
    const body = decamelize(json);
    return axios.delete(url, body).then(camelize);
  },
};
