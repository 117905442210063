import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  header: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    marginBottom: 40,
  },

  textUnderLine: {
    textDecoration: 'underline',
    textTransform: 'uppercase',
  },

  textArea: {
    '& textarea': {
      '&: focus': {
        border: 'none',
      },
      boxShadow: 'none',
      border: 'none',
    },
    width: '100%',
  },

  textField: {
    width: '100%',
  },

  description: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(22),
    marginBottom: 40,
  },

  formRow: {
    marginBottom: 20,
    alignItems: 'normal',
    overflowWrap: 'break-word',
  },

  label: {
    marginTop: -8,
  },

  scheme: {
    height: 100,
    marginBottom: 20,
  },

  name: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    marginRight: 20,
  },

  colorButton: {
    marginBottom: 5,
  },

  error: {
    color: theme.palette.colors.pomegranate,
  },

  emailToSupport: {
    fontSize: theme.typography.pxToRem(10),
  },

  qrCode: {
    width: 250,
  },

  appLinks: {
    paddingTop: 10,
  },
}));
