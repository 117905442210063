import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  menuItem: {
    width: 41,
    height: 41,
    borderRadius: 25,
    margin: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.2)',
    marginTop: -20,
    marginBottom: 28,
    '& svg': {
      width: '30%',
    },
  },

  label: {
    fontSize: theme.typography.pxToRem(7),
    fontWeight: 'bold',
    lineHeight: theme.typography.pxToRem(15),
    marginBottom: 3,
  },
}));
