import Presenter from 'utils/PropTypesPresenter';

const OWN_REQUEST_TYPE = 'own_request';

export default new Presenter(
  {},
  {
    agencyThemeAttributes({
      colorType,
      colorScheme,
      colorSchemeType,
      color,
      coverImage,
      emailInvitationVideoType,
      emailInvitationVideoUrl,
      agencyBrandedSpanishVideoUrl,
      emailInvitationVideoEmbedCode,
      spanishVideoEmbedCode,
      brandingMarketingAssetsUrl,
      ezlynxTextNumber,
      ezlynxText,
      carrierValue,
      appIcon,
    }) {
      return {
        colorType,
        colorScheme,
        colorSchemeType,
        color,
        coverImage,
        emailInvitationVideoType,
        emailInvitationVideoUrl,
        agencyBrandedSpanishVideoUrl,
        emailInvitationVideoEmbedCode,
        spanishVideoEmbedCode,
        brandingMarketingAssetsUrl,
        ezlynxTextNumber,
        ezlynxText,
        carrierValue,
        appIcon,
      };
    },
    agencyInfoAttributes(profile) {
      return {
        title: profile.title,
        email: profile.email,
        phone: profile.phone,
        textingPhone: profile.textingPhone,
        websiteUrl: profile.websiteUrl,
        certificateRequestType: profile.certificateRequestType,
        certificateRequestUrl:
          profile.certificateRequestType === OWN_REQUEST_TYPE ? profile.certificateRequestUrl : null,
        changeRequestType: profile.changeRequestType,
        changeRequestUrl: profile.changeRequestType === OWN_REQUEST_TYPE ? profile.changeRequestUrl : null,
        ezlynxTextNumber: profile.ezlynxTextNumber,
        ezlynxText: profile.ezlynxText,
      };
    },
    policyDetailsAttributes(profile) {
      return {
        carrierValue: profile.carrierValue,
      };
    },
  },
);
