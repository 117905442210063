export default {
  MuiButton: {
    root: {
      borderRadius: 2,
    },

    containedPrimary: {
      textTransform: 'none',
      fontWeight: 'bold',
    },

    textPrimary: {
      padding: 0,
      minWidth: 'auto',
      textTransform: 'none',
      fontWeight: 'bold',
    },
  },
};
