import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    width: 430,
    padding: 40,
  },

  title: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: 20,
  },

  groupTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: '600',
    marginBottom: 10,
    marginTop: 20,
  },

  icons: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 30,
  },

  iconWrapper: {
    width: 55,
    height: 55,
    padding: 3,
    cursor: 'pointer',
  },

  iconImage: {
    height: '100%',
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
