import Routes from 'Routes';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'ramda';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormRow from 'components/FormRow';
import LocationForm from 'forms/LocationForm';

import StateSelect from './components/StateSelect';
import useStyles from './styles';

const Location = ({
  location,
  onChangeProfile,
  onSave,
  agencyId,
  showSuccessfullySavedFlash,
  setIsLocationUnsaved,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  const { name, street, city, state, zip, phone } = location;
  const { street: streetError, city: cityError, state: stateError, zip: zipError } = errors;

  const handleChange = field => event => {
    setIsLocationUnsaved(true);
    let { value } = event.target;
    if (field === 'zip' && value.length > 5) {
      value = value.replace(/\s|-/g, '');
      value = `${value.slice(0, 5)}-${value.slice(5)}`;
    }

    onChangeProfile({ location: { ...location, [field]: value } });
  };

  const handleSave = () => {
    const currentErrors = LocationForm.validate(location);

    if (isEmpty(currentErrors)) {
      setErrors(currentErrors);
      return onSave({ location })
        .then(setIsLocationUnsaved(false))
        .then(() => showSuccessfullySavedFlash('Location(s) successfully saved.'));
    }

    setErrors(currentErrors);
    return null;
  };

  return (
    <>
      <div className={classes.header}>PRIMARY LOCATION</div>

      <FormRow label="Location Name" className={classes.formRow}>
        <TextField
          value={name || ''}
          onChange={handleChange('name')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <FormRow label="Street*" className={classes.formRow}>
        <TextField
          value={street || ''}
          onChange={handleChange('street')}
          variant="outlined"
          size="small"
          className={classes.textField}
          error={!isNil(streetError)}
          helperText={!isNil(streetError) && streetError}
        />
      </FormRow>

      <FormRow label="City*" className={classes.formRow}>
        <TextField
          value={city || ''}
          onChange={handleChange('city')}
          variant="outlined"
          size="small"
          className={classes.textField}
          error={!isNil(cityError)}
          helperText={!isNil(cityError) && cityError}
        />
      </FormRow>

      <FormRow label="State*" className={classes.formRow}>
        <StateSelect
          onChange={handleChange('state')}
          state={state || ''}
          error={!isNil(stateError)}
          helperText={!isNil(stateError) && stateError}
        />
      </FormRow>

      <FormRow label="Zip Code*" className={classes.formRow}>
        <TextField
          value={zip || ''}
          onChange={handleChange('zip')}
          variant="outlined"
          size="small"
          className={classes.textField}
          error={!isNil(zipError)}
          helperText={!isNil(zipError) && zipError}
        />
      </FormRow>

      <FormRow label="Phone" className={classes.formRow}>
        <TextField
          value={phone || ''}
          onChange={handleChange('phone')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <div className={classes.description}>
        <a href={Routes.agencyLocationsPath(agencyId)}>Click here to add/edit locations and phone numbers</a>
      </div>

      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  );
};

Location.propTypes = {
  location: PropTypes.shape().isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  agencyId: PropTypes.number.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  setIsLocationUnsaved: PropTypes.func.isRequired,
};

export default Location;
