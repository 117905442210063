import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formRow: {
    marginBottom: 10,
    height: 60,
  },

  header: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    marginBottom: 40,
  },

  description: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(22),
    margin: [[20, 0, 40]],
  },

  textField: {
    width: '100%',
  },

  addButton: {
    display: 'block',
    marginBottom: 20,
  },

  clearButton: {
    marginLeft: 10,
  },

  formRowContent: {
    display: 'flex',
    alignItems: 'center',
  },

  helpIcon: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },

  messageHelp: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    margin: [[80, 0, 20]],
  },

  noteText: {
    fontSize: theme.typography.pxToRem(10),
    height: 20,
    margin: [[90, 0, 0]],
  },

  helperText: {
    fontSize: theme.typography.pxToRem(10),
    height: 20,
    fontWeight: 'bold',
  },

  bottomText: {
    fontSize: theme.typography.pxToRem(10),
    height: 20,
    margin: [[30, 0, 0]],
  },

  textArea: {
    '& textarea': {
      '&: focus': {
        border: 'none',
      },
      boxShadow: 'none',
      border: 'none',
    },
    width: '100%',
  },

  learnMore: {
    fontSize: theme.typography.pxToRem(10),
    height: 20,
    margin: [[30, 0, 0]],
  },
}));
