import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useStyles from './styles';

const Paper = ({ children, className }) => {
  const classes = useStyles();

  return <div className={cn(className, classes.root)}>{children}</div>;
};

Paper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Paper.defaultProps = {
  className: '',
};

export default Paper;
