import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { ICONS, ICON_GROUPS } from 'utils/IconsUtils';

import useStyles from './styles';

const LinkIconModal = ({ onClose, onChangeIcon }) => {
  const classes = useStyles();

  const handleChangeIcon = (icon, src) => () => {
    onChangeIcon(icon, src);
    onClose();
  };

  const icons = Object.entries(ICONS).map(item => {
    const [itemKey, itemValue] = item;
    return {
      ...itemValue,
      value: itemKey,
    };
  });

  const renderIcons = group => {
    const currentIcons = icons.filter(({ groups }) => groups.includes(group));
    const sortedIcons = currentIcons.sort((first, second) => first.value.localeCompare(second.value));
    return sortedIcons.map(item => {
      const { src, value, hidden } = item;

      if (hidden) {
        return null;
      }

      return (
        <div className={classes.iconWrapper} onClick={handleChangeIcon(value, src)} role="presentation" key={value}>
          <img className={classes.iconImage} src={src} alt="" />
        </div>
      );
    });
  };

  const renderIconGroups = () => {
    const iconGroups = Object.values(ICON_GROUPS);
    return iconGroups.map(group => {
      return (
        <>
          <div className={classes.groupTitle}>{group}</div>
          <div className={classes.icons}>{renderIcons(group)}</div>
        </>
      );
    });
  };

  return (
    <Dialog open className={classes.modal} onClose={onClose}>
      <Paper className={classes.paper}>
        <div className={classes.title}>Edit Icon</div>
        <div>{renderIconGroups()}</div>
        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
};

LinkIconModal.propTypes = {
  onChangeIcon: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LinkIconModal;
