import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formRow: {
    marginBottom: 10,
    height: 60,
  },

  header: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    marginBottom: 40,
  },

  description: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(22),
    marginBottom: 40,
  },

  textField: {
    marginRight: 10,
    width: 284,
  },

  formRowContent: {
    display: 'flex',
    alignItems: 'center',
  },

  formRowLabel: {
    width: '27%',
  },

  formRowChildren: {
    width: '73%',
  },
}));
