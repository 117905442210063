import Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  update(id, params) {
    const path = Routes.apiInternalV1AgencyPath(id);
    return FetchHelpers.patch(path, params);
  },

  updateAgencySetting(id, params) {
    const path = Routes.apiInternalV1AgencyAgencyEmailSettingPath(id);
    return FetchHelpers.post(path, { agency_email_setting: params });
  },
};
