import PropTypes from 'prop-types';
import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    url: PropTypes.string,
    icon: PropTypes.string,
  },
  {
    defaultAttributes(socialPage = {}) {
      return {
        url: this.url(socialPage) || '',
        icon: this.icon(socialPage) || '',
        src: '',
      };
    },
  },
);
