import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'wrap',
    justifyContent: 'center',
  },

  imageWrapper: {
    width: 30,
    height: 30,
    overflow: 'hidden',
    padding: 10,
  },

  image: {
    height: '100%',
  },
}));
