import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: 30,
    height: 30,
    marginRight: 10,
  },

  image: {
    height: '100%',
    cursor: 'pointer',
  },
}));
