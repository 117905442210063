import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tabs: {
    borderBottom: [[1, 'solid', theme.palette.border]],
    marginBottom: 25,
  },

  tab: {
    color: theme.palette.text.primary,
    paddingRight: 8,

    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },

    '& button': {
      opacity: 'initial',
    },
  },
}));
