import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, path } from 'ramda';
import { ICONS } from 'utils/IconsUtils';

import useStyles from './styles';

const SocialIcons = ({ socialPages }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {socialPages
        .filter(page => !isEmpty(page.url))
        .map(item => {
          const { icon } = item;
          const src = path([icon, 'src'], ICONS);

          return (
            <div key={item.id} className={classes.imageWrapper}>
              <img src={src} alt={icon} className={classes.image} />
            </div>
          );
        })}
    </div>
  );
};

SocialIcons.propTypes = {
  socialPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SocialIcons;
