import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import Presenter from 'utils/PropTypesPresenter';

const LocationSchema = Yup.object().shape({
  name: Yup.string().label('Name'),
  street: Yup.string()
    .required()
    .nullable()
    .label('Street'),
  city: Yup.string()
    .required()
    .nullable()
    .label('City'),
  state: Yup.string()
    .required()
    .nullable()
    .label('State'),
  zip: Yup.string()
    .required()
    .nullable()
    .label('Zip Code'),
  phone: Yup.string().label('Phone'),
});

export default new Presenter(
  {
    name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
  },
  {
    defaultAttributes(location = {}) {
      return {
        name: this.name(location) || '',
        street: this.street(location) || '',
        city: this.city(location) || '',
        state: this.state(location) || '',
        zip: this.zip(location) || '',
        phone: this.phone(location) || '',
      };
    },

    validate(form) {
      try {
        LocationSchema.validateSync(form, { abortEarly: false });
        return {};
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
  },
);
