const COLORS = {
  white: '#fff',
  red: '#FF0000',
  pomegranate: '#F44336',
  alto: '#D8D8D8',
  silverChalice: '#A1A1A1',
  oceanGreen: '#469e84',
  eucalyptus: '#278469',
  shamrock: '#4cd1aa',
  lochinvar: '#299576',
};

export default {
  colors: COLORS,
  text: {
    primary: '#303030',
    secondary: '#b3b3b3',
  },
  paper: '#FFFFFF',
  border: '#EFEFEF',
  primary: {
    light: '#7bd4ff',
    main: '#41a3d7',
    dark: '#0074a5',
    contrastText: '#ffffff',
  },
};
