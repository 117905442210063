import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import Call from './icons/Call';
import Chat from './icons/Chat';
import Email from './icons/EmailIcon';
import Location from './icons/LocationIcon';
import ReferAgency from './icons/ReferAgency';
import Team from './icons/TeamIcon';
import Website from './icons/WebsiteIcon';
import AutoId from './icons/AutoId';

const Icon = ({ icon, color, className }) => {
  const ICONS = {
    call: Call,
    chat: Chat,
    email: Email,
    location: Location,
    referAgency: ReferAgency,
    team: Team,
    website: Website,
    autoId: AutoId,
  };

  const IconComponent = ICONS[icon];

  if (isNil(icon)) {
    return null;
  }

  return <IconComponent color={color} className={className} />;
};

Icon.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  icon: null,
  className: '',
};

export default Icon;
