import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import useStyles from './styles';

const MenuItem = ({ icon, label, iconColor, textColor, backgroundColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.menuItem} style={{ backgroundColor }}>
      <Icon icon={icon} color={iconColor} className={classes.icon} />
      <div className={classes.label} style={{ color: textColor }}>
        {label}
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default MenuItem;
