import React from 'react';
import PropTypes from 'prop-types';

const Chat = ({ color }) => {
  return (
    <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dasboard_1" transform="translate(-51.000000, -351.000000)" fillRule="nonzero">
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-7" transform="translate(0.000000, 79.000000)">
              <g id="Group" transform="translate(26.000000, 17.000000)">
                <path
                  d="M2.2524,11.9893472 L0.940593,16.9461581 L6.000255,13.9817222 C6.89585226,14.2217098 7.81816446,14.3427052 8.744238,14.3416972 C13.573536,14.3416972 17.488458,11.1517522 17.488458,7.21675278 C17.488458,3.28175333 13.573536,0.0918541667 8.744238,0.0918541667 C3.91494,0.0918541667 1.8e-05,3.28179611 1.8e-05,7.21675889 C1.8e-05,9.05270167 0.852705,10.726095 2.2524,11.9893472 Z"
                  id="Shape"
                  fill={color}
                />
                <path
                  d="M13.7367835,6.7135814 L3.72396302,6.7135814 C3.44191174,6.7135814 3.25387755,6.9135814 3.25387755,7.2135814 C3.25387755,7.5135814 3.44191174,7.7135814 3.72396302,7.7135814 L13.7837921,7.7135814 C14.0658434,7.7135814 14.2538776,7.5135814 14.2538776,7.2135814 C14.2538776,6.9135814 14.0188348,6.7135814 13.7367835,6.7135814 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M4.79279971,5.06669767 L12.7149554,5.06669767 C13.0383087,5.06669767 13.2538776,4.86669767 13.2538776,4.56669767 C13.2538776,4.26669767 13.0383087,4.06669767 12.7149554,4.06669767 L4.79279971,4.06669767 C4.46944641,4.06669767 4.25387755,4.26669767 4.25387755,4.56669767 C4.25387755,4.86669767 4.46944641,5.06669767 4.79279971,5.06669767 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
                <path
                  d="M12.7149554,9.18930233 L4.79279971,9.18930233 C4.46944641,9.18930233 4.25387755,9.38930233 4.25387755,9.68930233 C4.25387755,9.98930233 4.46944641,10.1893023 4.79279971,10.1893023 L12.7149554,10.1893023 C13.0383087,10.1893023 13.2538776,9.98930233 13.2538776,9.68930233 C13.2538776,9.43930233 12.9844165,9.18930233 12.7149554,9.18930233 Z"
                  id="Path"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Chat.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Chat;
