export default {
  MuiInputBase: {
    root: {
      '& input': {
        '&:focus': {
          border: 'none',
        },
      },
    },
  },
};
