import React from 'react';
import PropTypes from 'prop-types';

const Call = ({ color }) => {
  return (
    <svg width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dasboard_1" transform="translate(-50.000000, -269.000000)" fillRule="nonzero" strokeWidth="0.5">
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-6" transform="translate(0.000000, 2.000000)">
              <g id="Group" transform="translate(25.940500, 12.940500)">
                <path
                  d="M13.9550491,11.3781306 C13.4507811,10.8311051 12.5863215,10.9405102 12.1901108,11.5604724 C11.6138045,12.50865 11.3256513,12.8733337 11.1095364,12.8733337 C9.02042573,12.5815867 4.69812786,8.02304082 4.48201297,5.87140714 C4.48201297,5.76200204 4.44599382,5.54319184 5.77870233,4.74088776 C6.39102786,4.37620408 6.49908531,3.4644949 5.99481722,2.95393776 C3.61755339,0.583493878 3.50949595,-1.77635684e-15 2.71707467,-1.77635684e-15 C1.24028956,-1.77635684e-15 -0.164457246,3.86564694 0.0156384986,5.50672347 C0.483887435,9.40883878 8.19198531,17.5048163 11.7939002,17.5048163 C13.3427236,17.5048163 16.7285236,16.2284235 16.7645428,14.8061571 C16.7645428,14.0038531 16.1882364,13.894448 13.9550491,11.3781306 Z"
                  id="Shape"
                  stroke={color}
                  fill={color}
                />
                <path
                  d="M8.87634914,3.86564694 C11.253613,3.86564694 13.198647,5.83493878 13.198647,8.24185102 C13.198647,8.46066122 13.3427236,8.60653469 13.5588385,8.60653469 C13.7749534,8.60653469 13.91903,8.46066122 13.91903,8.24185102 C13.91903,5.43378673 11.6498236,3.13627959 8.87634914,3.13627959 C8.66023424,3.13627959 8.51615765,3.28215306 8.51615765,3.50096327 C8.51615765,3.71977347 8.69625339,3.86564694 8.87634914,3.86564694 Z"
                  id="Path"
                  stroke={color}
                  fill={color}
                />
                <path
                  d="M8.5521768,6.16315408 C8.5521768,6.38196429 8.69625339,6.52783776 8.91236829,6.52783776 C9.84886616,6.52783776 10.6052683,7.29367347 10.6052683,8.24185102 C10.6052683,8.46066122 10.7493449,8.60653469 10.9654598,8.60653469 C11.1815747,8.60653469 11.3256513,8.46066122 11.3256513,8.24185102 C11.3256513,6.89252143 10.2450768,5.79847041 8.91236829,5.79847041 C8.69625339,5.79847041 8.5521768,5.98081224 8.5521768,6.16315408 Z"
                  id="Path"
                  stroke={color}
                  fill={color}
                />
                <path
                  d="M8.91236829,1.23992449 C12.7303981,1.23992449 15.8280449,4.37620408 15.8280449,8.24185102 C15.8280449,8.46066122 15.9721215,8.60653469 16.1882364,8.60653469 C16.4043513,8.60653469 16.5484279,8.46066122 16.5484279,8.24185102 C16.5484279,3.97505204 13.1266087,0.510557143 8.91236829,0.510557143 C8.69625339,0.510557143 8.5521768,0.656430612 8.5521768,0.875240816 C8.5521768,1.09405102 8.69625339,1.23992449 8.91236829,1.23992449 Z"
                  id="Path"
                  stroke={color}
                  fill={color}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

Call.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Call;
