export default {
  profileBasePath: () => '/profile',

  profileAgencyThemePath: () => '/profile/agency_theme',
  profileAgencyInfoPath: () => '/profile/agency_info',
  profileLocationPath: () => '/profile/location',
  profilePreferredLinksPath: () => '/profile/preferred_links',
  profileSettingPath: () => '/profile/settings',
  profilePolicyDetailsPath: () => '/profile/policy_details',
};
