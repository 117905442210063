import React from 'react';
import PropTypes from 'prop-types';

const WebsiteIcon = ({ color }) => {
  return (
    <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dasboard_1" transform="translate(-307.000000, -273.000000)" fill={color} fillRule="nonzero">
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-7" transform="translate(256.000000, 0.000000)">
              <g id="Group" transform="translate(26.000000, 18.000000)">
                <path
                  d="M10.1663308,16.6937286 C11.0137814,15.1493638 11.6161855,13.4750047 11.9506845,11.7341857 L16.2173769,11.7341857 C15.1501489,14.34234 12.8701152,16.2110954 10.1663308,16.6937286 L10.1663308,16.6937286 Z"
                  id="Path"
                />
                <path
                  d="M7.95897678,15.9834429 C8.12394959,16.2998773 8.44405534,16.497242 8.79230769,16.497242 C9.14056005,16.497242 9.4606658,16.2998773 9.6256386,15.9834429 C10.3213922,14.6487021 10.8273917,13.2176211 11.1280846,11.7341857 L6.45653077,11.7341857 C6.7572237,13.2176211 7.26322319,14.6487021 7.95897678,15.9834429 L7.95897678,15.9834429 Z"
                  id="Path"
                />
                <path
                  d="M7.41842322,16.6937286 C4.71463866,16.2110955 2.43460481,14.3423401 1.36737678,11.7341857 L5.63406909,11.7341857 C5.96856817,13.4750047 6.5709724,15.1493638 7.41842322,16.6937286 L7.41842322,16.6937286 Z"
                  id="Path"
                />
                <path
                  d="M12.0892846,6.09727143 C12.3147575,7.69061078 12.3147575,9.30928922 12.0892846,10.9026286 L16.5081462,10.9026286 C16.9665015,9.33574771 16.9665015,7.66415229 16.5081462,6.09727143 L12.0892846,6.09727143 Z"
                  id="Path"
                />
                <path
                  d="M11.2743,10.9026286 C11.5117759,9.31021244 11.5117759,7.68968756 11.2743,6.09727143 L6.31031552,6.09727143 C6.07283961,7.68968756 6.07283961,9.31021244 6.31031552,10.9026286 L11.2743,10.9026286 Z"
                  id="Path"
                />
                <path
                  d="M5.49533077,10.9026286 C5.26985792,9.30928922 5.26985792,7.69061078 5.49533077,6.09727143 L1.07646909,6.09727143 C0.61811372,7.66415229 0.61811372,9.33574771 1.07646909,10.9026286 L5.49533077,10.9026286 Z"
                  id="Path"
                />
                <path
                  d="M10.1663308,0.3062 C11.0137801,1.85056033 11.6161841,3.52491451 11.9506845,5.26572857 L16.2173769,5.26572857 C15.1501452,2.65757979 12.8701122,0.788830346 10.1663308,0.3062 L10.1663308,0.3062 Z"
                  id="Path"
                />
                <path
                  d="M7.95897678,1.01647143 C7.26322319,2.35121219 6.7572237,3.78229318 6.45653077,5.26572857 L11.1280846,5.26572857 C10.8273904,3.7822981 10.321391,2.35122201 9.6256386,1.01648571 C9.46066835,0.70004983 9.14056419,0.502679473 8.79231183,0.502679473 C8.44405947,0.502679473 8.12395214,0.700038372 7.95897678,1.01647143 L7.95897678,1.01647143 Z"
                  id="Path"
                />
                <path
                  d="M1.36737678,5.26572857 L5.63406909,5.26572857 C5.96856959,3.52491448 6.57097378,1.8505603 7.41842322,0.3062 C4.71464173,0.788830256 2.43460854,2.65757971 1.36737678,5.26572857 L1.36737678,5.26572857 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

WebsiteIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default WebsiteIcon;
