import React from 'react';
import PropTypes from 'prop-types';

const ReferAgency = ({ color }) => {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dasboard_1" transform="translate(-305.000000, -349.000000)" fill={color} fillRule="nonzero">
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-7" transform="translate(255.000000, 79.000000)">
              <g id="share-(1)" transform="translate(25.000000, 15.000000)">
                <path
                  d="M15.503717,12.0739789 C14.3945213,12.0739789 13.4161426,12.597962 12.776934,13.4003713 L6.84812553,10.393962 C6.92574255,10.1069177 6.98152979,9.81033333 6.98152979,9.4981962 C6.98152979,9.15840084 6.91527234,8.83848734 6.82281915,8.52719198 L12.7254723,5.5346519 C13.3611638,6.37337764 14.3648489,6.92157173 15.504566,6.92157173 C17.4352085,6.92157173 18.9969681,5.37299156 18.9969681,3.46036498 C18.9969681,1.54946203 17.4352085,0 15.5046064,0 C13.5783298,0 12.0148319,1.54946203 12.0148319,3.46032489 C12.0148319,3.77334388 12.0706596,4.07077004 12.1491255,4.3586962 L6.22116596,7.36510549 C5.58110851,6.56185443 4.60095106,6.03614768 3.49001702,6.03614768 C1.56111277,6.03614768 0.000242553191,7.58645148 0.000242553191,9.4981962 C0.000242553191,11.4099409 1.56115319,12.959403 3.49001702,12.959403 C4.63147234,12.959403 5.63430851,12.4094852 6.27258723,11.5699177 L12.1726128,14.5624578 C12.0801596,14.8728713 12.0130532,15.1953903 12.0130532,15.5360675 C12.0130532,17.4478122 13.5765511,18.9972743 15.5028277,18.9972743 C17.4334702,18.9972743 18.9952298,17.4478122 18.9952298,15.5360675 C18.9961191,13.6225992 17.4343596,12.0739789 15.503717,12.0739789 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

ReferAgency.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ReferAgency;
