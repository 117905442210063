import Button from './Button';
import Tab from './Tab';
import FormControlLabel from './FormControlLabel';
import Input from './Input';

export default {
  ...Button,
  ...Tab,
  ...FormControlLabel,
  ...Input,
};
