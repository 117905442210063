import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = ({ color }) => {
  return (
    <svg width="25px" height="16px" viewBox="0 0 25 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dasboard_1" transform="translate(-217.000000, -273.000000)" fill={color} fillRule="nonzero">
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-9" transform="translate(170.000000, 0.000000)">
              <g id="Group" transform="translate(22.000000, 18.000000)">
                <path
                  d="M1.83879573,0.11144 C1.43557622,0.11144 1.09917988,0.171563077 0.828887195,0.303747692 L11.8807165,9.39987692 C12.329311,9.62147692 12.670689,9.62147692 13.1192835,9.39987692 L24.1711128,0.303747692 C23.9073537,0.174670769 23.587375,0.11144 23.199314,0.11144 L1.83879573,0.11144 Z M0.0857469512,1.26528615 C0.0325670732,1.46848615 0,1.70076308 0,1.95759385 L0,13.6499077 C0,13.9663385 0.042527439,14.2417846 0.123856707,14.4768 L7.94588415,7.76528615 L0.0857469512,1.26528615 Z M24.9237805,1.26528615 L17.0541159,7.76528615 L24.8761433,14.4768 C24.9588293,14.2360308 25,13.9484308 25,13.6210462 L25,1.92873231 C25,1.68356308 24.9741768,1.46140923 24.9237805,1.26528615 L24.9237805,1.26528615 Z M8.91768293,8.55372308 L0.98132622,15.3614154 C1.22601524,15.4520615 1.52019512,15.4960615 1.85785061,15.4960615 L23.1802591,15.4960615 C23.5004451,15.4960615 23.7827683,15.4484308 24.0186738,15.3614154 L16.0823171,8.55372308 C15.2895427,9.19855385 14.4823201,9.87753846 13.6909299,10.5056615 C12.9357317,10.8867385 12.0642683,10.8867385 11.3090701,10.5056615 C10.5116128,9.87624615 9.71094512,9.20421538 8.91768293,8.55372308 L8.91768293,8.55372308 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

EmailIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default EmailIcon;
