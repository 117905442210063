import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { camelize } from 'utils/keysConverter';

import FormRow from 'components/FormRow';
import ProfileForm from 'forms/ProfileForm';

import useStyles from './styles';

const PROGRAM_TYPE = {
  program: 'program',
  both: 'both',
};

const PolicyDetails = ({ profile, onChangeProfile, onSaveProfile, showSuccessfullySavedFlash, setInfoUnsaved }) => {
  const classes = useStyles();
  const [setErrors] = useState({});

  const { carrierValue } = profile;
  const handleChange = field => event => {
    setInfoUnsaved(true);

    onChangeProfile({ [field]: event.target.value });
  };

  const handleSaveProfile = () => {
    const attributes = { agency: ProfileForm.policyDetailsAttributes(profile) };
    onSaveProfile(attributes)
      .then(() => setInfoUnsaved(false))
      .then(() => showSuccessfullySavedFlash('Policy Details successfully saved.'))
      .catch(
        ({
          response: {
            data: { errors: profileErrors },
          },
        }) => {
          setErrors(camelize(profileErrors));
        },
      );
  };

  return (
    <>
      <div className={classes.header}>CARRIER / PROGRAM LISTING IN POLICY DETAILS</div>
      <div className={classes.noteText} id="signInButton">
        <p>
          The <b>CURRENT IA App display setting</b> for policies written through Carrier Programs or MGA/Broker displays
          only the carrier field data. To fix this, you can select what values to display.
        </p>
        <p>
          PLEASE check with HawkSoft regarding your Carrier and Programs set-up. It is not straightforward. PLEASE ask
          HS for assistance.
        </p>
        <p>
          <b>NOTE</b> - selections ONLY pertain IF a policy has data in BOTH CARRIER and PROGRAM fields.
        </p>
        <p>
          <b>If you choose:</b> List ONLY the PROGRAM NAME on a policy, please be 100% sure Company and Programs are
          set-up correctly in HawkSoft. If you use are not sure you set it up correctly in HS select List COMPANY &
          PROGRAM Name. One will be the name your client recognizes!
        </p>
        <p>
          <b>IMPORTANT:</b> Selecting/Changing the setting below requires IA App to resync all agency data. Please{' '}
          <b>allow 24-36 hours for the change to be reflected</b> on your dashboard and online & in the app.
        </p>
      </div>
      <FormRow label="Select Carrier Value" className={classes.formRow}>
        <RadioGroup value={carrierValue} onChange={handleChange('carrierValue')}>
          <FormControlLabel
            value={PROGRAM_TYPE.program}
            control={<Radio color="primary" size="small bold" />}
            label="List Only Company Program Name"
            labelPlacement="end"
          />
          <FormControlLabel
            value={PROGRAM_TYPE.both}
            control={<Radio color="primary" size="small bold" />}
            label="List Company Name & Company Program Name"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormRow>
      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleSaveProfile}>
          Save
        </Button>
      </div>
    </>
  );
};

PolicyDetails.propTypes = {
  profile: PropTypes.shape().isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  setInfoUnsaved: PropTypes.func.isRequired,
};

export default PolicyDetails;
