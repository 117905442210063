import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'ramda';
import MenuItem from './components/MenuItem';
import useStyles from './styles';

const Menu = ({ profile, iconColor, textColor, backgroundColor }) => {
  const classes = useStyles();
  const { email, phone, textingPhone, websiteUrl, location, integrationService, hideEzlynxFields } = profile;
  return (
    <div className={classes.root}>
      {!isEmpty(phone) && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="call"
          label="Call"
        />
      )}
      {!isEmpty(textingPhone) && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="chat"
          label="Text"
        />
      )}
      {!isEmpty(email) && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="email"
          label="Email"
        />
      )}
      {!isEmpty(websiteUrl) && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="website"
          label="Website"
        />
      )}
      <MenuItem
        iconColor={iconColor}
        textColor={textColor}
        backgroundColor={backgroundColor}
        icon="team"
        label="Team"
      />
      {!isEmpty(location.street) && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="location"
          label="Location"
        />
      )}
      <MenuItem
        iconColor={iconColor}
        textColor={textColor}
        backgroundColor={backgroundColor}
        icon="referAgency"
        label="Refer"
      />
      {integrationService === 'ezlynx' && hideEzlynxFields === false && (
        <MenuItem
          iconColor={iconColor}
          textColor={textColor}
          backgroundColor={backgroundColor}
          icon="autoId"
          label="Auto ID"
        />
      )}
    </div>
  );
};

Menu.propTypes = {
  profile: PropTypes.shape().isRequired,
  iconColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default Menu;
