import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormRow from 'components/FormRow';
import { getAgencySettings } from '../../../../repositories/AgencySettingRepository';
import useStyles from './styles';

function ProfileSettings({ onSaveSettings, agencyId, showSuccessfullySavedFlash, setIsEmailSettingsUnsaved }) {
  // const [errors, setErrors] = useState({});
  const [changeRequest, setChangeRequest] = useState('');
  const [certificateRequest, setCertificateRequest] = useState('');
  const [documentSharing, setDocumentSharing] = useState('');
  const [inventoryReceipents, setInventoryReceipents] = useState('');
  const [accidentReportReceipents, setAccidentReportReceipents] = useState('');
  const [propertyReportReceipents, setPropertyReportReceipents] = useState('');
  const [workplaceReportReceipents, setWorkplaceReportReceipents] = useState('');
  const [univitedAppDownloadReceipents, setUninvitedAppDownloadReceipents] = useState('');
  const [isNotifyAgent, setIsNotifyAgent] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    getAgencySettings(agencyId)
      .then(({ data }) => {
        setChangeRequest(data.changeRequestRecipients);
        setCertificateRequest(data.certificateRequestRecipients);
        setDocumentSharing(data.documentRecipients);
        setUninvitedAppDownloadReceipents(data.uninvitedAppDownloadRecipients);
        setWorkplaceReportReceipents(data.workplaceReportRecipients);
        setPropertyReportReceipents(data.propertyReportRecipients);
        setAccidentReportReceipents(data.accidentReportRecipients);
        setInventoryReceipents(data.inventoryRecipients);
        setIsNotifyAgent(data.notifyAgent);
      })
      .catch(error => {
        console.log(`Unable to get the settings ${error.message}`);
      });
  }, [getAgencySettings]);

  const onSave = e => {
    e.preventDefault();
    onSaveSettings({
      changeRequestRecipients: changeRequest,
      certificateRequestRecipients: certificateRequest,
      documentRecipients: documentSharing,
      inventoryRecipients: inventoryReceipents,
      accidentReportRecipients: accidentReportReceipents,
      propertyReportRecipients: propertyReportReceipents,
      workplaceReportRecipients: workplaceReportReceipents,
      uninvitedAppDownloadRecipients: univitedAppDownloadReceipents,
      notifyAgent: isNotifyAgent,
    }).then(() => {
      showSuccessfullySavedFlash('Settings successfully saved.');
    });
  };

  return (
    <>
      <div className={classes.header}>EMAIL SETTINGS FOR REQUESTS & REPORTS</div>
      <div
        style={{
          marginTop: -12,
          marginBottom: 16,
          fontSize: 14,
        }}
      >
        <b>Default email notification settings</b>: If fields are left blank the default email notifications setting
        will be followed. Default settings: Notification email sent to: Agency Email (entered on Agency Info TAB);
        Agency Admin (Owner), and if client is assigned to an agent on the IA App Dashboard then an email will also be
        sent to the Agent.
        <br />
        <br />
        <b>To Customize email notification settings</b>: Entering email address(es) in the fields below overrides the
        default email notification settings. Separate multiple emails in the fields below with , or ;
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <input
          type="checkbox"
          checked={isNotifyAgent}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setIsNotifyAgent(event.target.checked);
          }}
        />
        <label style={{ marginLeft: 16 }}>
          Check this box to have email notifications also sent to the agent when a customer is assigned to the agent on
          the IA App dashboard.
        </label>
      </div>
      <FormRow label="Change Request Emails" className={classes.formRow}>
        <TextField
          value={changeRequest}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setChangeRequest(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Certificate Request Emails">
        <TextField
          value={certificateRequest}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setCertificateRequest(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Document Share">
        <TextField
          value={documentSharing}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setDocumentSharing(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Inventory Report">
        <TextField
          value={inventoryReceipents}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setInventoryReceipents(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Accident Report">
        <TextField
          value={accidentReportReceipents}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setAccidentReportReceipents(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Property Report">
        <TextField
          value={propertyReportReceipents}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setPropertyReportReceipents(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Workplace Report">
        <TextField
          value={workplaceReportReceipents}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setWorkplaceReportReceipents(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <FormRow style={{ height: 110 }} className={classes.formRow} label="Uninvited App Downloads">
        <TextField
          value={univitedAppDownloadReceipents}
          onChange={event => {
            setIsEmailSettingsUnsaved(true);
            setUninvitedAppDownloadReceipents(event.target.value);
          }}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>
      <div className={classes.button}>
        <Button onClick={onSave} variant="contained" color="primary">
          Save
        </Button>
      </div>
    </>
  );
}

ProfileSettings.propTypes = {
  agencyId: PropTypes.number.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  setIsEmailSettingsUnsaved: PropTypes.func.isRequired,
};

export default ProfileSettings;
