// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles'
import palette from './palette';
import overrides from './overrides';

const theme = createTheme({
  palette,
  overrides,
});

export default theme;
