import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import LinkIconModal from './components/LinkIconModal';
import useStyles from './styles';

const LinkIcon = ({ icon, src, onChangeIcon }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        {!isEmpty(src) && (
          <img className={classes.image} src={src} alt={icon} onClick={handleOpen} role="presentation" />
        )}
      </div>
      {open && <LinkIconModal onClose={handleClose} onChangeIcon={onChangeIcon} />}
    </>
  );
};

LinkIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  onChangeIcon: PropTypes.func.isRequired,
};

export default LinkIcon;
