import React from 'react';
import PropTypes from 'prop-types';
import { find, isNil, isEmpty } from 'ramda';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormRow from 'components/FormRow';
import SocialPageForm from 'forms/SocialPageForm';
import { ICONS } from 'utils/IconsUtils';

import LinkIcon from './components/LinkIcon';
import useStyles from './styles';

const MAX_PAGES_COUNT = 8;

const URL_PLACEHOLDERS = {
  0: 'Suggestions for Preferred Links',
  1: 'URL for Your FB page',
  2: 'URL for Google review page',
  3: 'URL for Your calendar program',
  4: 'URL for Blog Page',
  5: 'URL for Insurance Information Page',
  6: 'URL for Referral Program',
  7: 'URL for Policy Review Survey',
};

const PreferredLinks = ({
  socialPages: existedSocialPages,
  onChangeProfile,
  onSaveProfile,
  preferredLinksGuideLink,
  showSuccessfullySavedFlash,
  setIsPreferredLinksUnsaved,
}) => {
  const classes = useStyles();
  const defaultPageRow = SocialPageForm.defaultAttributes();

  const blankPagesLength = MAX_PAGES_COUNT - existedSocialPages.length;
  const blankPages = Array(blankPagesLength).fill(defaultPageRow);
  const socialPages = [...existedSocialPages, ...blankPages].map((page, index) => ({
    ...page,
    placeholder: URL_PLACEHOLDERS[index],
  }));

  const getIconSource = icon => {
    const item = ICONS[icon];

    return item ? item.src : '';
  };

  const handleChangePage = indexRow => ({ target: { value } }) => {
    setIsPreferredLinksUnsaved(true);
    const currentIcon = find(([, iconValue]) => value.toLowerCase().match(iconValue.regexp), Object.entries(ICONS));

    let src = '';
    let icon = '';

    if (!isNil(currentIcon) && value) {
      const [currentIconKey, currentIconValue] = currentIcon;

      src = currentIconValue.src;
      icon = currentIconKey;
    }

    const currentSocialPages = socialPages.map((item, index) => {
      if (index === indexRow) {
        return {
          ...item,
          src,
          icon,
          url: value,
        };
      }

      return item;
    });

    onChangeProfile({ socialPages: currentSocialPages });
  };

  const handleBlurTextField = indexRow => () => {
    const socialUrl = socialPages[indexRow].url;

    const modifiedUrl = socialUrl.match(/http\S?:\/\//) ? socialUrl : `http://${socialUrl}`;

    const currentSocialPages = socialPages.map((item, index) => {
      if (index === indexRow) {
        return {
          ...item,
          url: isEmpty(socialUrl) ? '' : modifiedUrl,
        };
      }

      return item;
    });

    onChangeProfile({ socialPages: currentSocialPages });
  };

  const handleClearPage = indexRow => () => {
    setIsPreferredLinksUnsaved(true);
    const currentSocialPages = socialPages.map((item, index) => {
      if (index === indexRow) {
        return { ...item, url: '', icon: '', src: '' };
      }

      return item;
    });

    onChangeProfile({ socialPages: currentSocialPages });
  };

  const handleChangeIcon = indexRow => (icon, src) => {
    setIsPreferredLinksUnsaved(true);
    const currentSocialPages = socialPages.map((item, index) => {
      if (index === indexRow) {
        return {
          ...item,
          src,
          icon,
        };
      }

      return item;
    });

    onChangeProfile({ socialPages: currentSocialPages });
  };

  const handleSaveProfile = () => {
    const socialPagesAttributes = socialPages
      .filter(page => page.url || page.id)
      .map(page => {
        const shouldDestroy = !page.url && page.id;
        return shouldDestroy ? { ...page, _destroy: true } : page;
      });
    const attributes = { agency: { socialPagesAttributes } };

    onSaveProfile(attributes)
      .then(setIsPreferredLinksUnsaved(false))
      .then(() => showSuccessfullySavedFlash('Preffered Links successfully saved.'));
  };

  const renderLinks = () => {
    return socialPages.map((page, indexRow) => {
      const { url, icon } = page;
      const src = page.src || getIconSource(icon);

      return (
        <FormRow
          key={indexRow}
          label={`Preferred Link #${indexRow + 1}`}
          className={classes.formRow}
          labelClassName={classes.formRowLabel}
          childrenClassName={classes.formRowChildren}
        >
          <div className={classes.formRowContent} onBlur={handleBlurTextField(indexRow)}>
            <TextField
              value={url}
              onChange={handleChangePage(indexRow)}
              variant="outlined"
              size="small"
              className={classes.textField}
              placeholder={page.placeholder}
            />
            <LinkIcon icon={icon} src={src} onChangeIcon={handleChangeIcon(indexRow)} />
            {page.url && (
              <Button variant="text" color="primary" disableRipple onClick={handleClearPage(indexRow)}>
                Clear
              </Button>
            )}
          </div>
        </FormRow>
      );
    });
  };

  return (
    <>
      <div className={classes.header}>PREFERRED LINKS</div>

      <div className={classes.description}>
        Enter Preferred Link URLs below by replacing the inactive placeholder.
        <br /> Once you enter a URL an icon will appear to the right. Click icon for selection of alternate icons.
        Please TEST links in the app! Click SAVE.
        <br />
        <a target="_blank" rel="noopener noreferrer" href={preferredLinksGuideLink}>
          Download Guide
        </a>
        .
      </div>

      {renderLinks()}

      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleSaveProfile}>
          Save
        </Button>
      </div>
    </>
  );
};

PreferredLinks.propTypes = {
  socialPages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  preferredLinksGuideLink: PropTypes.string.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  setIsPreferredLinksUnsaved: PropTypes.func.isRequired,
};

export default PreferredLinks;
