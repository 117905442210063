import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

const CustomThemeProvider = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

CustomThemeProvider.defaultProps = {
  children: null,
};

export default CustomThemeProvider;
