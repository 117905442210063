import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const Container = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
