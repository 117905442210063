import Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  update(id, params) {
    const path = Routes.apiInternalV1AgencyAgencyEmailSettingPath(id);
    return FetchHelpers.post(path, params);
  },
};

export function getAgencySettings(agencyId) {
  const path = Routes.apiInternalV1AgencyAgencyEmailSettingPath(agencyId);
  return FetchHelpers.get(path);
}
