import PropTypes from 'prop-types';
import PropTypesPresenter from 'utils/PropTypesPresenter';

export default new PropTypesPresenter(
  {
    certificateRequestType: PropTypes.string.isRequired,
    changeRequestType: PropTypes.string.isRequired,
  },
  {
    hasCertificateRequest(profile) {
      const requestType = this.certificateRequestType(profile);
      return requestType !== 'without_request';
    },
    hasChangeRequest(profile) {
      const requestType = this.changeRequestType(profile);
      return requestType !== 'without_request';
    },
  },
);
