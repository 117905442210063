import Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  get(params) {
    const path = Routes.s3ParamsPath();
    const withCamelize = false;
    return FetchHelpers.get(path, params, withCamelize);
  },
};
