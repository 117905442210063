import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import appRoutes from 'appRoutes';

import TabLabel from './components/TabLabel';
import useStyles from './styles';

const ProfileTabs = ({ haveUnsavedChanges, isHawksoft }) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <Tabs value={pathname} classes={{ root: classes.tabs }} indicatorColor="primary">
        <Tab
          label={
            <TabLabel
              label="Agency Branding & Marketing"
              leftIndent={287}
              haveUnsavedChanges={haveUnsavedChanges.theme}
            />
          }
          to={appRoutes.profileAgencyThemePath()}
          value={appRoutes.profileAgencyThemePath()}
          component={Link}
          className={classes.tab}
        />

        <Tab
          label={<TabLabel label="Agency Info" leftIndent={275} haveUnsavedChanges={haveUnsavedChanges.info} />}
          to={appRoutes.profileAgencyInfoPath()}
          value={appRoutes.profileAgencyInfoPath()}
          component={Link}
          className={classes.tab}
        />

        <Tab
          label={
            <TabLabel
              label="Emails Settings for Requests & Reports"
              leftIndent={275}
              haveUnsavedChanges={haveUnsavedChanges.emailSettings}
            />
          }
          to={appRoutes.profileSettingPath()}
          value={appRoutes.profileSettingPath()}
          component={Link}
          className={classes.tab}
        />

        <Tab
          label={<TabLabel label="Location(s)" leftIndent={275} haveUnsavedChanges={haveUnsavedChanges.location} />}
          to={appRoutes.profileLocationPath()}
          value={appRoutes.profileLocationPath()}
          component={Link}
          className={classes.tab}
        />

        <Tab
          label={
            <TabLabel label="Preferred Links" leftIndent={275} haveUnsavedChanges={haveUnsavedChanges.preferredLinks} />
          }
          to={appRoutes.profilePreferredLinksPath()}
          value={appRoutes.profilePreferredLinksPath()}
          component={Link}
          className={classes.tab}
        />

        {isHawksoft && (
          <Tab
            label={
              <TabLabel
                label="Policy Details"
                leftIndent={275}
                haveUnsavedChanges={haveUnsavedChanges.policyDetails}
                isHawksoft={isHawksoft}
              />
            }
            to={appRoutes.profilePolicyDetailsPath()}
            value={appRoutes.profilePolicyDetailsPath()}
            component={Link}
            className={classes.tab}
          />
        )}
      </Tabs>
    </>
  );
};

ProfileTabs.propTypes = {
  haveUnsavedChanges: PropTypes.shape().isRequired,
  isHawksoft: PropTypes.bool.isRequired,
};

export default ProfileTabs;
