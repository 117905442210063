import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formRow: {
    marginBottom: 20,
    height: 60,
  },
  formRowProfile: {
    marginBottom: 20,
    height: 110,
  },
  header: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    marginBottom: 40,
  },

  description: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(22),
    marginBottom: 40,
  },

  textField: {
    width: '100%',
  },
}));
