import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import states from './states.json';
import useStyles from './styles';

const StateSelect = ({ state, onChange, error, helperText }) => {
  const classes = useStyles();

  const handleChange = event => {
    onChange(event);
  };

  return (
    <>
      <Select
        value={state}
        onChange={handleChange}
        variant="outlined"
        className={classes.root}
        classes={{ select: classes.select }}
        error={error}
      >
        {states.map(({ name, abbreviation }) => {
          return (
            <MenuItem key={abbreviation} value={abbreviation}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </>
  );
};

StateSelect.propTypes = {
  state: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

StateSelect.defaultProps = {
  helperText: '',
};

export default StateSelect;
