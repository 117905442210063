import Routes from 'Routes';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { camelize } from 'utils/keysConverter';

import FormRow from 'components/FormRow';
import ProfileForm from 'forms/ProfileForm';

import useStyles from './styles';

const REQUEST_TYPES = [
  { key: 'insurance_agent_request', label: 'Use in App default form' },
  { key: 'own_request', label: 'Link to Agency form' },
  { key: 'without_request', label: 'No Request Button' },
];

const AgencyInfo = ({
  profile,

  onChangeProfile,
  onSaveProfile,
  agencyId,
  showSuccessfullySavedFlash,
  setIsInfoUnsaved,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});

  const {
    title,
    email,
    phone,
    textingPhone,
    websiteUrl,
    certificateRequestUrl,
    changeRequestUrl,
    certificateRequestType,
    changeRequestType,
    ezlynxTextNumber,
    integrationService,
    ezlynxText,
    hideEzlynxFields,
  } = profile;

  const handleChange = field => event => {
    setIsInfoUnsaved(true);

    onChangeProfile({ [field]: event.target.value });
  };

  const handleSaveProfile = () => {
    setErrors({});

    const attributes = { agency: ProfileForm.agencyInfoAttributes(profile) };
    onSaveProfile(attributes)
      .then(() => setIsInfoUnsaved(false))
      .then(() => showSuccessfullySavedFlash('Agency Info successfully saved.'))
      .catch(
        ({
          response: {
            data: { errors: profileErrors },
          },
        }) => {
          setErrors(camelize(profileErrors));
        },
      );
  };

  const {
    certificateRequestUrl: certificateRequestUrlError,
    changeRequestUrl: changeRequestUrlError,
    email: emailError,
  } = errors;

  return (
    <>
      <div className={classes.header}>AGENCY INFO</div>
      <FormRow label="Agency Name" className={classes.formRow}>
        <TextField
          value={title}
          onChange={handleChange('title')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <FormRow label="Agency Email" className={classes.formRow}>
        <TextField
          value={email || ''}
          onChange={handleChange('email')}
          variant="outlined"
          size="small"
          className={classes.textField}
          error={!isNil(emailError)}
          helperText={!isNil(emailError) && emailError.join(', ')}
        />
      </FormRow>

      <FormRow label="Agency Phone #" className={classes.formRow}>
        <TextField
          value={phone || ''}
          onChange={handleChange('phone')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <FormRow label="Texting Phone #" className={classes.formRow}>
        <TextField
          value={textingPhone || ''}
          onChange={handleChange('textingPhone')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <FormRow label="Website" className={classes.formRow}>
        <TextField
          value={websiteUrl || ''}
          onChange={handleChange('websiteUrl')}
          variant="outlined"
          size="small"
          className={classes.textField}
        />
      </FormRow>

      <FormRow label="Certificate Request Button" className={classes.formRow}>
        <Select
          key={certificateRequestType}
          value={certificateRequestType}
          onChange={handleChange('certificateRequestType')}
        >
          {REQUEST_TYPES.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormRow>

      {certificateRequestType === 'own_request' && (
        <FormRow label="Certificate Request URL" className={classes.formRow}>
          <div className={classes.formRowContent}>
            <TextField
              value={certificateRequestUrl || ''}
              onChange={handleChange('certificateRequestUrl')}
              variant="outlined"
              size="small"
              className={classes.textField}
              error={!isNil(certificateRequestUrlError)}
              helperText={!isNil(certificateRequestUrlError) && certificateRequestUrlError.join(', ')}
            />
          </div>
        </FormRow>
      )}

      <FormRow label="Change Request Button" className={classes.formRow}>
        <Select key={changeRequestType} value={changeRequestType} onChange={handleChange('changeRequestType')}>
          {REQUEST_TYPES.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormRow>

      {changeRequestType === 'own_request' && (
        <FormRow label="Change Request URL" className={classes.formRow}>
          <div className={classes.formRowContent}>
            <TextField
              value={changeRequestUrl || ''}
              onChange={handleChange('changeRequestUrl')}
              variant="outlined"
              size="small"
              className={classes.textField}
              error={!isNil(changeRequestUrlError)}
              helperText={!isNil(changeRequestUrlError) && changeRequestUrlError.join(', ')}
            />
          </div>
        </FormRow>
      )}

      {integrationService === 'ezlynx' && hideEzlynxFields === false && (
        <>
          <FormRow label="Enter Text # for Eva, the virtual agent" className={classes.formRow}>
            <TextField
              value={ezlynxTextNumber || ''}
              onChange={handleChange('ezlynxTextNumber')}
              variant="outlined"
              size="small"
              className={classes.textField}
            />
          </FormRow>

          <FormRow label="EZLynx Auto ID Text Message" className={classes.formRow}>
            <div className={classes.formRowContent}>
              <TextField
                value={ezlynxText || 'Hi - Please send me my Auto ID cards.  \n Make: \n Model: \nThank you'}
                onChange={handleChange('ezlynxText')}
                variant="outlined"
                multiline
                classes={{ root: classes.textArea }}
              />
            </div>
          </FormRow>
          <div className={classes.noteText}>
            Note - You can edit the pre-filled message in the box. Keep it short & direct so Eva understands request.
          </div>

          <div className={classes.helperText}>
            IMPORTANT: Eva is a feature of the EZLYNX Text Messaging product in EZLynx Communication Center - you must
            have EZLynx Text Messaging product and Client Center for this to work optimally in the IA App.
          </div>

          <div className={classes.bottomText}>
            For more info about EZLynx Client Center product
            <a
              href="https://ezlynxsupport.freshdesk.com/en/support/solutions/articles/8000090289"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Click: How to Access EZLynx Client Center
            </a>
          </div>
        </>
      )}

      <div className={classes.description}>
        Please click <a href={Routes.agencyAgentsPath(agencyId)}>Agents</a> on main menu to manage your team.
      </div>

      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleSaveProfile}>
          Save
        </Button>
      </div>
    </>
  );
};

AgencyInfo.propTypes = {
  profile: PropTypes.shape().isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  agencyId: PropTypes.number.isRequired,
  setIsInfoUnsaved: PropTypes.func.isRequired,
};

export default AgencyInfo;
