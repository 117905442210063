import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },

  select: {
    paddingTop: 12,
    paddingBottom: 10,
    height: 18,
  },

  helperText: {
    color: theme.palette.error.main,
    margin: [[4, 14, 0]],
  },
}));
