import React from 'react';
import profileIcon from 'assets/images/profile.png';
import useStyles from './styles';

const Info = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.imageWrapper}>
          <img src={profileIcon} className={classes.image} alt="Profile" />
        </div>
        <div>AGENCY PROFILE</div>
      </div>

      <div className={classes.description}>
        Manage your agency branding and the agency information that appears in your app.
      </div>
    </div>
  );
};

export default Info;
