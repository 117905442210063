import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  label: {
    margin: 0,
  },

  icon: {
    color: theme.palette.colors.red,
    fontSize: 20,
    display: 'flex',
    marginLeft: 4,
    zIndex: 1,
  },
}));
