import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ color }) => {
  return (
    <svg width="13px" height="18px" viewBox="0 0 13 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
        <g
          id="Dasboard_1"
          transform="translate(-223.000000, -350.000000)"
          fill={color}
          stroke={color}
          strokeWidth="0.9580032"
        >
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-7" transform="translate(170.000000, 79.000000)">
              <path
                d="M34.5207547,16.5209984 C37.8239768,16.5209984 40.4790016,19.2282338 40.4790016,22.6 C40.4790016,24.8943543 38.0311465,29.084403 34.9050531,33.2859323 L34.5254782,33.7960882 L34.1398763,33.2904723 C31.0997823,29.304185 28.5209984,24.9096411 28.5209984,22.6 C28.5209984,19.2211813 31.1829712,16.5209984 34.5207547,16.5209984 Z M34.5207547,20.257949 C33.2509911,20.257949 32.2186242,21.3051345 32.2186242,22.6 C32.2186242,23.8581715 33.2563547,24.8999458 34.5207547,24.8999458 C35.7490089,24.8999458 36.7813758,23.8527603 36.7813758,22.6 C36.7813758,21.3106266 35.7542933,20.257949 34.5207547,20.257949 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LocationIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LocationIcon;
