import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { useSelector, Provider } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { compose, isNil, assocPath } from 'ramda';
import { configureStore } from '@reduxjs/toolkit';
import { useBeforeunload } from 'react-beforeunload';

import appRoutes from 'appRoutes';
import { camelize } from 'utils/keysConverter';
import Container from 'components/Container';
import Paper from 'components/Paper';
import AppLayout from 'layouts/AppLayout';

import Info from './components/Info';
import ProfileTabs from './components/ProfileTabs';
import AgencyTheme from './components/AgencyTheme';
import AgencyInfo from './components/AgencyInfo';
import Location from './components/Location';
import PreferredLinks from './components/PreferredLinks';
import PhoneImage from './components/PhoneImage';
import PolicyDetails from './components/PolicyDetails';

import ProfileSlice, { useProfileActions, getColorSchemeType, colorType, hexToRgb } from './ProfileSlice';

import useStyles from './styles';
import ProfileSettings from './components/ProfileSettings/ProfileSettings';

const FLASH_DURATION = 3000;

const store = configureStore({
  reducer: {
    ProfileSlice,
  },
});

const Profile = ({ agency, preferredLinksGuideLink, isAdmin, isHawksoft }) => {
  const classes = useStyles();
  const [showSuccessfullySavedMessage, setShowSuccessfullySavedMessage] = useState(false);
  const [flashMessage, setFlashMessage] = useState('Profile successfully saved.');
  const [haveUnsavedChanges, setHaveUnsavedChanges] = useState({
    info: false,
    theme: false,
    location: false,
    preferredLinks: false,
    emailSettings: false,
    policyDetails: false,
  });
  const {
    id: agencyId,
    email,
    title,
    originAddress,
    websiteUrl,
    coverImageUrl,
    phone,
    textingPhone,
    color,
    colorScheme,
    certificateRequestUrl,
    certificateRequestType,
    changeRequestUrl,
    changeRequestType,
    emailInvitationVideoUrl,
    agencyBrandedSpanishVideoUrl,
    emailInvitationVideoType,
    emailInvitationVideoEmbedCode,
    spanishVideoEmbedCode,
    primaryLocation,
    socialPages: defaultSocialPages,
    brandingMarketingAssetsUrl,
    hideEzlynxFields,
    ezlynxTextNumber,
    integrationService,
    ezlynxText,
    carrierValue,
    trackingId,
  } = camelize(agency);

  const {
    updateProfile,
    saveInfo,
    saveTheme,
    saveLocation,
    createLocation,
    savePreferredLinks,
    saveSettings,
    savePolicyDetails,
  } = useProfileActions();

  const { profile } = useSelector(state => state.ProfileSlice);
  const { location, socialPages } = profile;

  const setIsInfoUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, info: value });
  const setIsThemeUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, theme: value });
  const setIsLocationUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, location: value });
  const setIsPreferredLinksUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, preferredLinks: value });
  const setIsEmailSettingsUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, emailSettings: value });
  const setInfoUnsaved = value => setHaveUnsavedChanges({ ...haveUnsavedChanges, policyDetails: value });

  const showSuccessfullySavedFlash = message => {
    setFlashMessage(message);
    setShowSuccessfullySavedMessage(true);
    setTimeout(() => setShowSuccessfullySavedMessage(false), FLASH_DURATION);
  };

  const handleChangeProfile = value => updateProfile(value);

  const handleSaveAgencyTheme = attributes => saveTheme(agencyId, attributes);
  const handleSaveAgencyInfo = attributes => saveInfo(agencyId, attributes);
  const handleSavePolicyDetails = attributes => savePolicyDetails(agencyId, attributes);
  const handleSaveLocation = attributes => {
    if (isNil(location.id)) {
      const newAttrs = assocPath(['location', 'isPrimary'], true, attributes);
      return createLocation(agencyId, newAttrs);
    }
    return saveLocation(agencyId, location.id, attributes);
  };
  const handlePreferredLinks = attributes => savePreferredLinks(agencyId, attributes);

  const handleUpdateSettings = settings => saveSettings(agencyId, settings);

  const shouldPreventReload = Object.values(haveUnsavedChanges).some(value => value);

  useBeforeunload(event => (shouldPreventReload ? event.preventDefault() : null));

  useEffect(() => {
    document.getElementById('profile-page-loader').remove();

    updateProfile({
      email,
      title,
      originAddress,
      websiteUrl,
      coverImageUrl,
      colorSchemeType: getColorSchemeType(color),
      color,
      colorType: colorType(hexToRgb(color)),
      previousBrandColor: { color, colorScheme },
      colorScheme,
      phone,
      textingPhone,
      certificateRequestUrl,
      certificateRequestType,
      changeRequestUrl,
      changeRequestType,
      emailInvitationVideoUrl,
      agencyBrandedSpanishVideoUrl,
      emailInvitationVideoType,
      emailInvitationVideoEmbedCode,
      spanishVideoEmbedCode,
      brandingMarketingAssetsUrl: brandingMarketingAssetsUrl || '',
      location: primaryLocation || {},
      socialPages: defaultSocialPages || [],
      ezlynxTextNumber,
      integrationService,
      ezlynxText,
      carrierValue,
      trackingId,
      hideEzlynxFields,
    });
  }, []);

  const renderContent = () => {
    return (
      <>
        <Info />
        <ProfileTabs haveUnsavedChanges={haveUnsavedChanges} isHawksoft={isHawksoft} />

        <div className={classes.content}>
          <Paper className={classes.paper}>
            <Switch>
              <Route
                path={appRoutes.profileAgencyThemePath()}
                render={() => (
                  <AgencyTheme
                    profile={profile}
                    onChangeProfile={handleChangeProfile}
                    onSaveProfile={handleSaveAgencyTheme}
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    setIsThemeUnsaved={setIsThemeUnsaved}
                    canChangeAssetsLink={isAdmin}
                  />
                )}
              />

              <Route
                path={appRoutes.profileAgencyInfoPath()}
                render={() => (
                  <AgencyInfo
                    profile={profile}
                    onChangeProfile={handleChangeProfile}
                    agencyId={agencyId}
                    onSaveProfile={handleSaveAgencyInfo}
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    setIsInfoUnsaved={setIsInfoUnsaved}
                  />
                )}
              />

              <Route
                path={appRoutes.profileLocationPath()}
                render={() => (
                  <Location
                    location={location}
                    onChangeProfile={handleChangeProfile}
                    onSave={handleSaveLocation}
                    agencyId={agencyId}
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    setIsLocationUnsaved={setIsLocationUnsaved}
                  />
                )}
              />

              <Route
                path={appRoutes.profilePreferredLinksPath()}
                render={() => (
                  <PreferredLinks
                    socialPages={socialPages}
                    onChangeProfile={handleChangeProfile}
                    onSaveProfile={handlePreferredLinks}
                    preferredLinksGuideLink={preferredLinksGuideLink}
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    setIsPreferredLinksUnsaved={setIsPreferredLinksUnsaved}
                  />
                )}
              />

              <Route
                path={appRoutes.profileSettingPath()}
                render={() => (
                  <ProfileSettings
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    agencyId={agencyId}
                    setIsEmailSettingsUnsaved={setIsEmailSettingsUnsaved}
                    onSaveSettings={handleUpdateSettings}
                  />
                )}
              />

              <Route
                path={appRoutes.profilePolicyDetailsPath()}
                render={() => (
                  <PolicyDetails
                    profile={profile}
                    onChangeProfile={handleChangeProfile}
                    agencyId={agencyId}
                    onSaveProfile={handleSavePolicyDetails}
                    showSuccessfullySavedFlash={showSuccessfullySavedFlash}
                    setInfoUnsaved={setInfoUnsaved}
                  />
                )}
              />

              <Redirect to={appRoutes.profileAgencyThemePath()} />
            </Switch>
          </Paper>

          <div className={classes.phoneImage}>
            <PhoneImage profile={profile} />
          </div>
        </div>
      </>
    );
  };

  return (
    <HashRouter basename="/">
      <div>
        {showSuccessfullySavedMessage && (
          <div className={classes.flash}>
            <p className={classes.flashMessage}>{flashMessage}</p>
          </div>
        )}
        <Container>{renderContent()}</Container>
      </div>
    </HashRouter>
  );
};

const addStore = ProxyComponent => props => (
  <Provider store={store}>
    <ProxyComponent {...props} />
  </Provider>
);

Profile.propTypes = {
  agency: PropTypes.shape().isRequired,
  preferredLinksGuideLink: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isHawksoft: PropTypes.bool.isRequired,
};

export default compose(hot, addStore)(AppLayout(Profile));
