import React from 'react';
import PropTypes from 'prop-types';

const TeamIcon = ({ color }) => {
  return (
    <svg width="28px" height="18px" viewBox="0 0 28 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Dasboard_1"
          transform="translate(-133.000000, -349.000000)"
          fill={color}
          fillRule="nonzero"
          stroke={color}
          strokeWidth="1.3"
        >
          <g id="Group-2" transform="translate(25.000000, 255.000000)">
            <g id="Group-7" transform="translate(85.000000, 79.000000)">
              <g id="Group" transform="translate(23.000000, 15.000000)">
                <path
                  d="M18.1413597,14.9659807 C17.5868001,14.6613173 16.8948884,14.3766968 15.9990912,14.0693088 C15.704396,13.9681857 15.4025764,13.8693056 15.001169,13.7411613 C14.88826,13.7051165 14.7558893,13.6630568 14.5157001,13.5867536 C14.3237656,13.5256927 14.1833632,13.4806866 14.0490735,13.4370163 C13.3041746,13.1947791 12.8418401,13.0219317 12.4894424,12.8383242 C12.3585306,12.7701161 12.2442194,12.7005365 12.2078802,12.66803 C11.3576553,12.1775483 11.1862681,10.9663823 12.0554868,9.55737275 C12.7491491,8.62365883 13.2052478,7.38555035 13.35567,5.75365277 C13.6397152,2.16918678 12.2110899,0.65 9.63534763,0.65 C6.89089356,0.65 5.49601673,2.04905113 5.91278742,5.73062515 C6.10212525,7.37447771 6.63500494,8.60266809 7.48494655,9.56047676 C8.44309655,10.8663298 8.14497539,12.0702004 7.25968834,12.6265286 C7.09961076,12.7456068 6.90203186,12.8545968 6.65861982,12.9641025 C6.13616727,13.1991425 3.53262616,14.0487104 3.41248427,14.0915194 C2.6289148,14.3707213 2.00103433,14.6373284 1.48718504,14.9202223 C1.34037721,15.0035901 1.24522516,15.0855171 1.18516839,15.1666683 C1.11282645,15.2644197 1.09307993,15.3367517 1.0609741,15.59331 C1.05687254,15.6258002 1.05687254,15.6258002 1.05203539,15.6610716 C1.04224035,15.7302916 1.02389341,15.9957752 1.00298525,16.3757155 C0.995889369,16.5046609 0.988496593,16.6461122 0.980878461,16.7980348 C0.971984269,16.9754048 0.963066255,17.161255 0.954316251,17.35 L18.762123,17.35 C18.753373,17.161255 18.744455,16.9754048 18.7355608,16.7980348 C18.7279427,16.6461122 18.7205499,16.5046609 18.713454,16.3757155 C18.6986306,16.1063462 18.6850945,15.8945086 18.6749808,15.7693559 C18.6567186,15.7324198 18.6388182,15.6931113 18.620205,15.649935 C18.6094799,15.6250563 18.5416407,15.460982 18.5220975,15.4170268 C18.4900568,15.3449629 18.4607824,15.2884968 18.4288583,15.23827 C18.359011,15.1283777 18.2720144,15.0422584 18.1413597,14.9659807 Z"
                  id="Path"
                />
                <path
                  d="M18.391207,12.6054793 C18.947731,12.8141454 19.4209029,13.021941 19.8607051,13.2549063 C20.1336121,13.415622 20.3448564,13.6180942 20.4976667,13.8547315 C20.6022072,14.0166198 20.6739584,14.1847201 20.724117,14.3640017 C20.7537093,14.4697732 20.7736836,14.5709865 20.8049058,14.805 L20.7987099,14.7418422 L20.8049058,14.805 C20.8049058,14.8377544 20.8132311,14.9470163 20.8297988,15.119851 C20.8304697,15.1268498 20.8312898,15.1353007 20.8322382,15.145 L27.1185504,15.145 C27.1180394,15.1356636 27.1175207,15.1262763 27.1169942,15.116842 C27.0948653,14.7203046 27.0626029,14.3180598 27.0503709,14.2563156 C27.0101587,14.053335 27.0019277,14.0241825 26.9680703,13.9693644 C26.9390725,13.9224146 26.8940567,13.8780445 26.8382586,13.8436681 C26.458177,13.6332462 25.9751407,13.4353183 25.3424417,13.2202673 C25.1446209,13.1530291 24.9405764,13.0867124 24.6737776,13.0021978 C24.7077747,13.0129672 24.1689335,12.8430928 24.0285864,12.7980135 C23.5129757,12.6323998 23.189531,12.5148071 22.929663,12.3884441 C22.7876002,12.3193648 22.6672276,12.2482187 22.6263317,12.2109603 C21.9299582,11.7891468 21.7994337,10.8008663 22.494714,9.7257771 C22.9610412,9.12660287 23.2660784,8.27229073 23.3405695,7.14577957 C23.544787,4.70613225 22.6104677,3.71 20.8680962,3.71 C19.0361568,3.71 18.1286782,4.61781466 18.3947227,7.13592832 C18.5021243,8.22019883 18.8647908,9.07120217 19.4318509,9.74179595 C20.1580215,10.736723 19.9451356,11.6159353 19.2636709,12.1662336 C19.0756732,12.3138502 18.8299093,12.4371444 18.4982438,12.565926 C18.4696683,12.5770215 18.433538,12.5903442 18.391207,12.6054793 Z M28.2917762,15.3695674 L28.3596033,15.5065168 C28.3435512,15.4431189 28.3334878,15.4033736 28.2917762,15.3695674 Z M27.1383609,15.7214927 C27.1383357,15.7230657 27.1385371,15.7238986 27.1387912,15.7249487 C27.1388652,15.7235168 27.1389321,15.7217848 27.138991,15.7197658 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

TeamIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default TeamIcon;
