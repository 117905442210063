import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    paddingLeft: 5,
    color: theme.palette.text.primary,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(19),
  },

  imageWrapper: {
    marginRight: 12,
  },

  description: {
    marginLeft: 49,
    fontSize: theme.typography.pxToRem(13),
  },
}));
