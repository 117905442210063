import { values, forEach, pipe, isNil, not } from 'ramda';
import _ from 'lodash';

export const yupToFormErrors = yupErrors => {
  let errors = {};
  const yupErrorsValues = values(yupErrors.inner);

  const formatError = err => {
    if (!errors[err.path]) {
      errors = _.set(errors, err.path, err.message);
    }
  };

  forEach(formatError, yupErrorsValues);

  return errors;
};

export const isPresent = value => pipe(isNil, not)(value);
