/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AgenciesRepository from 'repositories/AgenciesRepository';
import AgencyLocationsRepository from 'repositories/AgencyLocationsRepository';
import ProfileForm from 'forms/ProfileForm';

export const DEFAULT_COLOR = '#2C7CA8';
export const getColorSchemeType = color => (color === DEFAULT_COLOR ? 'default' : 'brand');
export const COLOR_TYPE = {
  light: 'light',
  dark: 'dark',
};
export const colorType = rgb => {
  const { r, g, b } = rgb;
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp > 127.5 ? COLOR_TYPE.light : COLOR_TYPE.dark;
};
export const hexToRgb = (hex, opacity = 1) => {
  hex = hex.replace('#', '');
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
    a: opacity,
  };
};

const initialState = {
  profile: {
    coverImageUrl: '',
    tempCoverImage: {
      name: '',
      file: null,
    },

    colorType: 'dark',

    colorSchemeType: 'default',
    colorScheme: 'dark',
    color: DEFAULT_COLOR,
    title: '',
    email: '',
    textingPhone: '',
    websiteUrl: '',

    certificateRequestUrl: '',
    hasCertificateRequest: false,

    changeRequestUrl: '',
    hasChangeRequest: false,

    emailInvitationVideoUrl: '',
    agencyBrandedSpanishVideoUrl: '',
    emailInvitationVideoEmbedCode: '',
    spanishVideoEmbedCode: '',
    emailInvitationVideoType: 'own_video',
    brandingMarketingAssetsUrl: '',
    ezlynxTextNumber: '',
    ezlynxText: '',
    integrationService: '',
    location: {
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
    },
    settings: {
      changeRequestRecipients: '',
      certificateRequestRecipients: '',
      incidentReportRecipients: '',
      changeRequestAgent: false,
      certificateRequestAgent: false,
    },
    socialPages: [],
    integrations: [],
    carrierValue: '',
    trackingId: '',
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, { payload }) {
      state.profile = {
        ...state.profile,
        ...payload,
      };
    },
  },
});

export const { setProfile } = profileSlice.actions;

export default profileSlice.reducer;

export const useProfileActions = () => {
  const dispatch = useDispatch();

  const updateProfile = params => {
    dispatch(setProfile(params));
  };

  const saveInfo = (id, params) => {
    return AgenciesRepository.update(id, params).then(({ data }) => {
      dispatch(setProfile(ProfileForm.agencyInfoAttributes(data)));
    });
  };

  const saveTheme = (id, params) => {
    return AgenciesRepository.update(id, params).then(({ data }) => {
      dispatch(
        setProfile({
          ...ProfileForm.agencyThemeAttributes(data),
          colorSchemeType: getColorSchemeType(data.color),
          colorType: colorType(hexToRgb(data.color)),
        }),
      );
    });
  };

  const saveLocation = (agencyId, id, params) => {
    return AgencyLocationsRepository.update(agencyId, id, params).then(({ data }) => {
      dispatch(setProfile({ primaryLocation: data }));
    });
  };

  const createLocation = (agencyId, params) => {
    return AgencyLocationsRepository.create(agencyId, params).then(({ data }) => {
      dispatch(setProfile({ primaryLocation: data }));
    });
  };

  const savePreferredLinks = (id, params) => {
    return AgenciesRepository.update(id, params).then(({ data }) => {
      dispatch(setProfile({ socialPages: data.socialPages }));
    });
  };

  const saveSettings = (id, params) => {
    return AgenciesRepository.updateAgencySetting(id, params).then(({ data }) => {
      dispatch(setProfile({ settings: { ...data } }));
    });
  };

  const savePolicyDetails = (id, params) => {
    return AgenciesRepository.update(id, params).then(({ data }) => {
      dispatch(setProfile(ProfileForm.agencyInfoAttributes(data)));
    });
  };

  return {
    updateProfile,
    saveInfo,
    saveTheme,
    saveLocation,
    createLocation,
    savePreferredLinks,
    saveSettings,
    savePolicyDetails,
  };
};
