import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  label: {
    width: '44%',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    paddingRight: 10,
    paddingTop: 10,
    boxSizing: 'border-box',
  },

  children: {
    width: '57%',
  },
}));
