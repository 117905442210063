import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useStyles from './styles';

const FormRow = ({ children, label, className, labelClassName, childrenClassName, subTitle }) => {
  const classes = useStyles();

  return (
    <div className={cn(className, classes.root)}>
      <div className={cn(labelClassName, classes.label)}>
        <div>{label}</div>
        <div style={{ fontSize: 12, color: 'black', opacity: 0.7 }}>{subTitle || ''}</div>
      </div>
      <div className={cn(childrenClassName, classes.children)}>{children}</div>
    </div>
  );
};

FormRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  subTitle: PropTypes.string,
};

FormRow.defaultProps = {
  className: null,
  labelClassName: null,
  childrenClassName: null,
  subTitle: '',
};

export default FormRow;
