import React from 'react';
import PropTypes from 'prop-types';

const AutoId = ({ color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="300.000000pt"
      height="300.000000pt"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path d="M161 2636 c-48 -17 -108 -71 -134 -119 l-22 -42 -3 -974 c-3 -1099 -7 -1037 73 -1116 80 -81 -22 -76 1441 -73 l1300 3 49 26 c30 15 63 43 83 70 18 24 37 50 42 56 7 8 9 352 8 1010 l-3 998 -22 41 c-28 53 -91 107 -142 122 -57 17 -2622 15 -2670 -2z m1169 -401 l0 -85 -500 0 -500 0 0 85 0 85 500 0 500 0 0 -85z m1370 0 l0 -85 -610 0 -610 0 0 85 0 85 610 0 610 0 0 -85z m-64 -439 c36 -30 39 -86 6 -119 -22 -22 -26 -22 -376 -25 -217 -2 -363 1 -379 7 -33 13 -60 62 -51 96 3 14 18 34 31 45 25 19 42 20 384 20 l358 0 27 -24z m-1106 -76 l0 -90 -600 0 -600 0 0 90 0 90 600 0 600 0 0 -90z m1091 -245 c55 -29 61 -97 12 -135 -25 -19 -42 -20 -383 -20 -341 0 -358 1 -383 20 -31 25 -41 63 -25 98 24 50 37 52 406 52 290 0 348 -2 373 -15z m-1091 -140 l0 -105 -600 0 -600 0 0 105 0 105 600 0 600 0 0 -105z m1101 -198 c44 -34 47 -89 7 -127 -21 -19 -34 -20 -387 -20 -394 1 -390 0 -410 53 -12 31 0 75 26 95 24 17 741 17 764 -1z m-1101 -207 l0 -100 -600 0 -600 0 0 100 0 100 600 0 600 0 0 -100z" />
      </g>
    </svg>
  );
};

AutoId.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AutoId;
