import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { isNil, head, isEmpty } from 'ramda';

import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import gon from 'gon';
import cn from 'classnames';
import universalQrCode from 'assets/images/agencyProfile/universalQrCode.jpeg';
import S3ParamsRepository from 'repositories/S3ParamsRepository';
import S3Repository from 'repositories/S3Repository';

import { DEFAULT_COLOR, COLOR_TYPE, colorType, hexToRgb } from 'containers/Profile/ProfileSlice';
import FormRow from 'components/FormRow';
import ProfileForm from 'forms/ProfileForm';
import { camelize } from 'utils/keysConverter';

import useStyles from './styles';

const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

const COLOR_SCHEME_TYPE = {
  default: 'default',
  brand: 'brand',
};

const EMAIL_INVITATION_VIDEO_TYPES = [
  { key: 'insurance_agent_video', label: 'Default/Generic Video' },
  { key: 'own_video', label: 'Agency Branded Video' },
];

const AgencyTheme = ({
  profile,
  onChangeProfile,
  onSaveProfile,
  showSuccessfullySavedFlash,
  setIsThemeUnsaved,
  canChangeAssetsLink,
}) => {
  const classes = useStyles();
  const fileInput = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({});

  const {
    tempCoverImage,
    colorSchemeType,
    colorScheme,
    color,
    previousBrandColor,
    emailInvitationVideoType,
    emailInvitationVideoUrl,
    agencyBrandedSpanishVideoUrl,
    emailInvitationVideoEmbedCode,
    spanishVideoEmbedCode,
    brandingMarketingAssetsUrl,
    trackingId,
    tempAppIconImage,
    appIconImageUrl,
  } = profile;

  const {
    image: imageErrors,
    emailInvitationVideoUrl: emailInvitationVideoUrlError,
    agencyBrandedSpanishVideoUrl: agencyBrandedSpanishVideoUrlError,
    emailInvitationVideoEmbedCode: emailInvitationVideoEmbedCodeError,
    spanishVideoEmbedCode: spanishVideoEmbedCodeError,
    brandingMarketingAssetsUrl: brandingMarketingAssetsUrlError,
  } = errors;

  const colorPickerRef = useRef();
  const handleClickInput = () => {
    fileInput.current.click();
  };

  const handleChange = field => event => {
    setIsThemeUnsaved(true);
    const brandColor = previousBrandColor ? previousBrandColor.color : DEFAULT_COLOR;
    const theme =
      event.target.value === 'default'
        ? {
            [field]: event.target.value,
            colorScheme: COLOR_TYPE.dark,
            colorType: COLOR_TYPE.dark,
            color: DEFAULT_COLOR,
          }
        : {
            [field]: event.target.value,
            color: brandColor,
            colorType: colorType(hexToRgb(brandColor)),
          };
    if (event.target.value === 'brand' && previousBrandColor) theme.colorScheme = previousBrandColor.colorScheme;
    const previousColorScheme = field === 'colorScheme' ? theme.colorScheme : previousBrandColor.colorScheme;

    onChangeProfile({
      ...profile,
      ...theme,
      previousBrandColor: {
        ...previousBrandColor,
        colorScheme: previousColorScheme,
      },
    });
  };

  const handleSaveProfile = () => {
    setErrors({});
    const attributes = { agency: ProfileForm.agencyThemeAttributes(profile) };
    onSaveProfile(attributes)
      .then(setIsThemeUnsaved(false))
      .then(() => showSuccessfullySavedFlash('Agency app cover image & color successfully saved.'))
      .catch(
        ({
          response: {
            data: { errors: profileErrors },
          },
        }) => {
          setErrors(camelize(profileErrors));
        },
      );
  };

  const handleChangeEmailInvitatioVideoType = (e, value) => {
    setIsThemeUnsaved(true);
    onChangeProfile({ ...profile, emailInvitationVideoType: value });
  };

  const handleTextFieldChange = field => e => {
    setIsThemeUnsaved(true);
    onChangeProfile({ ...profile, [field]: e.target.value });
  };

  const handleOpenColorPicker = () => {
    setAnchorEl(colorPickerRef.current);
  };

  const handleCloseColorPicker = () => {
    setAnchorEl(null);
  };

  const handleChangeBrandColor = brandColor => {
    const brandColorParams = {
      color: brandColor.hex,
      colorType: colorType(brandColor.rgb),
    };

    setIsThemeUnsaved(true);
    onChangeProfile({
      ...brandColorParams,
      previousBrandColor: {
        ...previousBrandColor,
        color: brandColorParams.color,
      },
    });
  };

  const handleChangeImage = event => {
    setErrors({});
    const image = head(event.target.files);

    const coverImage = {
      storage: 'presign_cache',
      metadata: {
        size: image.size,
        filename: image.name,
        mimeType: image.type,
      },
    };

    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({ image: ['File size must be less than 10 MB'] });
    } else {
      const reader = new FileReader();
      reader.onloadend = x => {
        const presignedParams = { filename: image.name, type: image.type };
        S3ParamsRepository.get(presignedParams).then(response => {
          const {
            data: { url, fields, headers },
          } = response;
          const params = { ...fields, file: image };

          [, coverImage.id] = fields.key.match(/^cache\/(.+)/);

          onChangeProfile({
            coverImage: JSON.stringify(coverImage),
            tempCoverImage: { file: x.target.result, name: image.name },
          });

          return S3Repository.post(url, headers, params);
        });
      };

      setIsThemeUnsaved(true);
      reader.readAsDataURL(image);
    }
  };

  const handleDeleteFile = () => {
    onChangeProfile({
      coverImage: {},
      tempCoverImage: { name: '', file: null },
    });
    setErrors({});
  };

  const showSpanishVideoUrls = () => {
    if (emailInvitationVideoType === 'own_video') {
      if (canChangeAssetsLink === true) {
        return true;
      }
      if (
        canChangeAssetsLink === false &&
        agencyBrandedSpanishVideoUrl !== null &&
        agencyBrandedSpanishVideoUrl !== ''
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const showSpanishEmbed = () => {
    if (emailInvitationVideoType === 'own_video') {
      if (canChangeAssetsLink === true) {
        return true;
      }
      if (canChangeAssetsLink === false && spanishVideoEmbedCode !== null && spanishVideoEmbedCode !== '') {
        return true;
      }
      return false;
    }
    return false;
  };

  /* discuss this change */
  function getUrlSuffix() {
    const currentUrlPath = window.location.href;

    if (currentUrlPath.includes('https://ezlynx.insuranceagentapp.com')) {
      return 'https://customer-ezlynx.insuranceagentapp.com';
    }
    if (currentUrlPath.includes('https://staging.insuranceagentapp.com')) {
      return 'https://customer-staging.insuranceagentapp.com';
    }
    return 'https://customer.insuranceagentapp.com';
  }
  const showUrl = showSpanishVideoUrls();
  const showEmbedCode = showSpanishEmbed();

  const webliteAppUrl = getUrlSuffix();

  const renderAppIconComingSoon = () => {
    return (
      <div
        style={{
          maxWidth: 140,
          height: 12,
          paddingleft: 8,
          paddingRight: 8,
          paddingTop: 12,
          paddingBottom: 4,
          backgroundColor: '#f33176',
          borderRadius: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <label
          style={{
            fontSize: 16,
            color: 'white',
            fontWeight: '400',
          }}
        >
          Coming soon
        </label>
      </div>
    );
  };

  const renderFile = () => {
    if (isNil(tempCoverImage) || isNil(tempCoverImage.file)) {
      return (
        <div>
          <input
            ref={fileInput}
            onChange={handleChangeImage}
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
          />
          <Button variant="text" color="primary" disableRipple onClick={handleClickInput}>
            Browse
          </Button>
          {!isNil((tempAppIconImage && tempAppIconImage.file) || appIconImageUrl) && (
            <div
              style={{
                height: 80,
                width: 80,
              }}
            >
              <img
                style={{
                  height: 80,
                  width: 80,
                }}
                alt="app_icon"
                src={tempAppIconImage.file || appIconImageUrl}
              />
            </div>
          )}
          {!isNil(imageErrors) && <p className={classes.error}>{imageErrors.join(', ')}</p>}
        </div>
      );
    }

    const { name } = tempCoverImage;
    return (
      <div>
        <span className={classes.name}>{name}</span>
        <Button variant="text" color="primary" disableRipple onClick={handleDeleteFile}>
          Delete
        </Button>
        {!isNil((tempAppIconImage && tempAppIconImage.file) || appIconImageUrl) && (
          <div
            style={{
              height: 80,
              width: 80,
            }}
          >
            <img
              style={{
                height: 80,
                width: 80,
              }}
              alt="app_icon"
              src={tempAppIconImage.file || appIconImageUrl}
            />
          </div>
        )}
      </div>
    );
  };

  const renderScheme = () => {
    return (
      <RadioGroup value={colorScheme} onChange={handleChange('colorScheme')}>
        <FormControlLabel
          value={COLOR_TYPE.dark}
          control={<Radio color="primary" size="small" />}
          label="Dark Brand Scheme"
          labelPlacement="end"
        />
        <FormControlLabel
          value={COLOR_TYPE.light}
          control={<Radio color="primary" size="small" />}
          label="Light Brand Scheme"
          labelPlacement="end"
        />
      </RadioGroup>
    );
  };

  const renderColor = () => {
    return (
      <>
        <Button
          variant="text"
          color="primary"
          disableRipple
          onClick={handleOpenColorPicker}
          ref={colorPickerRef}
          className={classes.colorButton}
        >
          {isNil(color) ? 'Pick Color' : color}
        </Button>
        {anchorEl && (
          <Menu
            MenuListProps={{ disablePadding: true }}
            id="color-picker-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseColorPicker}
          >
            <ChromePicker
              color={color}
              disableAlpha
              onChange={handleChangeBrandColor}
              onChangeComplete={handleChangeBrandColor}
            />
          </Menu>
        )}
      </>
    );
  };

  const renderBrandingAssetsInput = () => (
    <div className={classes.formRowContent}>
      <TextField
        value={brandingMarketingAssetsUrl || ''}
        onChange={handleTextFieldChange('brandingMarketingAssetsUrl')}
        variant="outlined"
        classes={{ root: classes.textArea }}
        placeholder="Request Link from Support"
        error={!isNil(brandingMarketingAssetsUrlError)}
        helperText={!isNil(brandingMarketingAssetsUrlError) && brandingMarketingAssetsUrlError}
      />
    </div>
  );

  const renderBrandingAssetsText = () => (
    <div className={cn([classes.formRowContent], [classes.appLinks])}>
      {isEmpty(brandingMarketingAssetsUrl) ? (
        <div>Request Link from Support</div>
      ) : (
        <a href={brandingMarketingAssetsUrl} target="_blank" rel="noopener noreferrer">
          {brandingMarketingAssetsUrl}
        </a>
      )}
    </div>
  );

  return (
    <>
      <div className={cn([classes.header], [classes.textUnderLine])}>
        AGENCY APP COVER IMAGE, APP ICON, ACCENT COLOR IN APP AND ONLINE
      </div>
      <div className={cn([classes.textUnderLine])}>APP COVER IMAGE</div>
      <div className={classes.description}>
        Size: 1290x980px, Top padding 10px, Bottom padding 30px.Backgrounds should not be transparent. Max file size
        10MB
        <br />
      </div>
      <div className={cn([classes.textUnderLine])}>APP ICON:</div>
      <div className={classes.description}>
        Size: 512x512px, 10px padding, App stores apply curvy edges so please submit square 512x512 image. Actual icon
        size will be between 45x45px to 72x72px depending on device. Max file size 10MB. Every app icon is reviewed by
        IA App and then submitted to the App stores for final approval. It takes 10-14 days from the start of the Review
        & Approval process before your custom app icon is available to your clients.
        <br />
        Have questions or want to get started on your icon? Contact support@insuranceagentapp.com and let us help!
        <br />
      </div>
      <FormRow label={<div className={classes.label}>Upload Cover Image</div>} className={classes.formRow}>
        <div>{renderFile()}</div>
      </FormRow>
      <FormRow label={<div className={classes.label}>Upload APP Icon</div>} className={classes.formRow}>
        <div>{renderAppIconComingSoon()}</div>
      </FormRow>
      <FormRow label="Color Scheme" subTitle="(Accent color for app and online)" className={classes.formRow}>
        <RadioGroup value={colorSchemeType} onChange={handleChange('colorSchemeType')}>
          <FormControlLabel
            value={COLOR_SCHEME_TYPE.default}
            control={<Radio color="primary" size="small" />}
            label="Default Color Scheme"
            labelPlacement="end"
          />
          <div>
            <FormControlLabel
              value={COLOR_SCHEME_TYPE.brand}
              control={<Radio color="primary" size="small" />}
              label="Brand Color"
              labelPlacement="end"
            />
            {colorSchemeType === 'brand' && renderColor()}
          </div>
        </RadioGroup>

        <div className={classes.scheme}>{colorSchemeType === COLOR_SCHEME_TYPE.brand && renderScheme()}</div>
      </FormRow>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        className={cn([classes.header], [classes.textUnderLine])}
      >
        Online Customer Portal Settings
        <div
          style={{
            background: 'green',
            width: 80,
            height: 14,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: '400',
            borderRadius: 40,
            textDecoration: 'none',
            borderBottom: '0px',
            marginLeft: '12px',
            paddingTop: 8,
          }}
        >
          <label style={{ textDecoration: 'none' }}>New</label>
        </div>
      </div>
      <FormRow
        label="URL"
        subTitle="(Add link to website, marketing, materials, anywhere agency is, or should be promoting customer service app to clients)"
        className={classes.formRow}
      >
        <div className={classes.formRowContent}>
          {trackingId && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${webliteAppUrl}?tracking_id=${trackingId}`}
            >{`${webliteAppUrl}?tracking_id=${trackingId}`}</a>
          )}
        </div>
      </FormRow>
      <div className={cn([classes.header], [classes.textUnderLine])}>Agency Branded Video</div>
      <FormRow label="Email Invite Video" className={classes.formRow}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={emailInvitationVideoType}
          onChange={handleChangeEmailInvitatioVideoType}
        >
          {EMAIL_INVITATION_VIDEO_TYPES.map(({ key, label }) => (
            <FormControlLabel value={key} control={<Radio color="primary" size="small" />} label={label} />
          ))}
        </RadioGroup>
      </FormRow>

      <div className={cn([classes.header], [classes.textUnderLine])}>Marketing, Resources and Guides</div>
      {emailInvitationVideoType === 'own_video' && (
        <>
          <FormRow label="Agency Branded Video URL *" className={classes.formRow}>
            <div className={classes.formRowContent}>
              <TextField
                value={emailInvitationVideoUrl || ''}
                onChange={handleTextFieldChange('emailInvitationVideoUrl')}
                variant="outlined"
                size="small"
                className={classes.textField}
                error={!isNil(emailInvitationVideoUrlError)}
                helperText={!isNil(emailInvitationVideoUrlError) && emailInvitationVideoUrlError}
                placeholder="Enter URL for agency specific video"
                required
              />
              <FormHelperText className={classes.emailToSupport}>
                Questions? Contact <a href={`mailto:${gon.supportEmail}`}>{gon.supportEmail}</a>
              </FormHelperText>
            </div>
          </FormRow>
          <FormRow label="Website Embed Code" className={classes.formRow}>
            <div className={classes.formRowContent}>
              <TextField
                value={emailInvitationVideoEmbedCode || ''}
                onChange={handleTextFieldChange('emailInvitationVideoEmbedCode')}
                variant="outlined"
                multiline
                rows={4}
                classes={{ root: classes.textArea }}
                error={!isNil(emailInvitationVideoEmbedCodeError)}
                helperText={!isNil(emailInvitationVideoEmbedCodeError) && emailInvitationVideoEmbedCodeError}
                placeholder="Embed code will be entered here when available"
              />
            </div>
          </FormRow>
        </>
      )}

      {showUrl === true && (
        <FormRow label="Spanish Agency Branded Video URL " className={classes.formRow}>
          <div className={classes.formRowContent}>
            <TextField
              value={agencyBrandedSpanishVideoUrl || ''}
              onChange={handleTextFieldChange('agencyBrandedSpanishVideoUrl')}
              variant="outlined"
              size="small"
              className={classes.textField}
              error={!isNil(agencyBrandedSpanishVideoUrlError)}
              helperText={!isNil(agencyBrandedSpanishVideoUrlError) && agencyBrandedSpanishVideoUrlError}
              placeholder={'Spanish Embed code will be entered here when available' || ''}
              required
            />
            <FormHelperText className={classes.emailToSupport}>
              Questions? Contact <a href={`mailto:${gon.supportEmail}`}>{gon.supportEmail}</a>
            </FormHelperText>
          </div>
        </FormRow>
      )}

      {showEmbedCode === true && (
        <FormRow label="Spanish Video Embed Code" className={classes.formRow}>
          <div className={classes.formRowContent}>
            <TextField
              value={spanishVideoEmbedCode || ''}
              onChange={handleTextFieldChange('spanishVideoEmbedCode')}
              variant="outlined"
              multiline
              rows={4}
              classes={{ root: classes.textArea }}
              error={!isNil(spanishVideoEmbedCodeError)}
              helperText={!isNil(spanishVideoEmbedCodeError) && spanishVideoEmbedCodeError}
              placeholder="Spanish Embed code will be entered here when available"
            />
          </div>
        </FormRow>
      )}

      <FormRow label="Branded Marketing Assets" className={classes.formRow}>
        {canChangeAssetsLink ? renderBrandingAssetsInput() : renderBrandingAssetsText()}
      </FormRow>

      <FormRow label="Basic Marketing Assets" className={classes.formRow}>
        <div className={cn([classes.formRowContent], [classes.appLinks])}>
          <a href={gon.basicMarketingAssetsLink} target="_blank" rel="noopener noreferrer">
            {gon.basicMarketingAssetsLink}
          </a>
        </div>
      </FormRow>
      <FormRow label="Quick (Universal) Link" className={classes.formRow}>
        <div className={cn([classes.formRowContent], [classes.appLinks])}>
          <a href={gon.appUniversalLink} target="_blank" rel="noopener noreferrer">
            {gon.appUniversalLink}
          </a>
        </div>
      </FormRow>
      <FormRow label="Apple Store" className={classes.formRow}>
        <div className={cn([classes.formRowContent], [classes.appLinks])}>
          <a href={gon.appStoreLink} target="_blank" rel="noopener noreferrer">
            {gon.appStoreLink}
          </a>
        </div>
      </FormRow>
      <FormRow label="Google Play Store" className={classes.formRow}>
        <div className={cn([classes.formRowContent], [classes.appLinks])}>
          <a href={gon.googlePlayLink} target="_blank" rel="noopener noreferrer">
            {gon.googlePlayLink}
          </a>
        </div>
      </FormRow>
      <FormRow label="Universal QR Code" className={classes.formRow}>
        <div className={cn([classes.formRowContent], [classes.appLinks])}>
          <img src={universalQrCode} className={classes.qrCode} alt="Profile" />
        </div>
      </FormRow>

      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={handleSaveProfile}>
          Save
        </Button>
      </div>
    </>
  );
};

AgencyTheme.propTypes = {
  profile: PropTypes.shape().isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  showSuccessfullySavedFlash: PropTypes.func.isRequired,
  setIsThemeUnsaved: PropTypes.func.isRequired,
  canChangeAssetsLink: PropTypes.bool.isRequired,
};

export default AgencyTheme;
