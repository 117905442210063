import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';

import useStyles from './styles';

const TabLabel = ({ label, haveUnsavedChanges, leftIndent }) => {
  const classes = useStyles();

  const renderIcon = () => (
    <Tooltip title="You have unsaved changes">
      <ErrorIcon className={classes.icon} style={{ left: leftIndent }} />
    </Tooltip>
  );

  return (
    <div className={classes.root}>
      <p className={classes.label}>{label}</p>
      {haveUnsavedChanges && renderIcon()}
    </div>
  );
};

TabLabel.propTypes = {
  label: PropTypes.string.isRequired,
  haveUnsavedChanges: PropTypes.bool.isRequired,
  leftIndent: PropTypes.number.isRequired,
};

export default TabLabel;
