import Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  update(agencyId, id, params) {
    const path = Routes.apiInternalV1AgencyLocationPath(agencyId, id);
    return FetchHelpers.patch(path, params);
  },

  create(agencyId, params) {
    const path = Routes.apiInternalV1AgencyLocationsPath(agencyId);
    return FetchHelpers.post(path, params);
  },
};
