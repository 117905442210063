import React from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty } from 'ramda';

import ProfilePresenter from 'presenters/ProfilePresenter';

import Menu from './components/Menu';
import Image from './components/Image';
import SocialIcons from './components/SocialIcons';
import useStyles from './styles';

const COLORS = {
  white: '#FFFFFF',
  black: '#000000',
};

const COLOR_TYPES = {
  dark: 'dark',
  light: 'light',
};

const PhoneImage = ({ profile }) => {
  const classes = useStyles();
  const { color, colorScheme, colorType, title, tempCoverImage, coverImageUrl, socialPages } = profile;

  const isDark = value => value === COLOR_TYPES.dark;

  const getTextColor = () => {
    if (isDark(colorType) && isDark(colorScheme)) return COLORS.white;

    return COLORS.black;
  };

  const getMenuIconColor = () => {
    if (isDark(colorScheme)) return color;

    return isDark(colorType) ? COLORS.white : COLORS.black;
  };

  const getMenuTextColor = () => {
    if (!isDark(colorType)) return COLORS.black;

    return isDark(colorScheme) ? COLORS.black : COLORS.white;
  };

  const insuranceButtonColor = isDark(colorType) ? COLORS.white : COLORS.black;
  const mainColor = isDark(colorScheme) ? color : COLORS.white;
  const textColor = getTextColor();
  const iconBackgroundColor = isDark(colorScheme) ? COLORS.white : color;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.name} style={{ color: textColor }}>
          {title}
        </div>

        <div className={classes.coverWrapper}>
          {isNil((tempCoverImage && tempCoverImage.file) || coverImageUrl) ? (
            <div className={classes.coverEmpty}>
              Agency App
              <br /> Cover Image
            </div>
          ) : (
            <img src={tempCoverImage.file || coverImageUrl} alt="Cover" className={classes.coverImage} />
          )}
        </div>

        <div className={classes.menuWrapper}>
          <div className={classes.menu} style={{ color: textColor, backgroundColor: mainColor }}>
            <Menu
              profile={profile}
              iconColor={getMenuIconColor()}
              textColor={getMenuTextColor()}
              backgroundColor={iconBackgroundColor}
            />
          </div>
          <div className={classes.requestButtonsWrapper}>
            {ProfilePresenter.hasChangeRequest(profile) && <div className={classes.requestButton}>Change Request</div>}
            {ProfilePresenter.hasCertificateRequest(profile) && (
              <div className={classes.requestButton}>Certificate Request</div>
            )}
          </div>
          <div className={classes.insuranceButtonWrapper}>
            <div className={classes.insuranceButton} style={{ color: insuranceButtonColor, backgroundColor: color }}>
              Insurance Companies - Pay Bill, Claims Info
            </div>
          </div>
          <div>
            {isEmpty(socialPages) ? (
              <div className={classes.linkPlace}>
                Preferred Link
                <br /> Place
              </div>
            ) : (
              <SocialIcons socialPages={socialPages} />
            )}
          </div>
        </div>
      </div>

      <div className={classes.image}>
        <Image mainColor={mainColor} textColor={textColor} />
      </div>
    </div>
  );
};

PhoneImage.propTypes = {
  profile: PropTypes.shape().isRequired,
};

export default PhoneImage;
